import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router,NavigationEnd } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-tempPass',
  templateUrl: './tempPass.component.html',
  styleUrls: ['./tempPass.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TempPass implements OnInit {
  
  public name: any;
  public customerId: any;
  public transactionId: any;
  public amount: any;
  public osType : any;
  public enc_data: any = {};
  public enc_data1: any = {};
  currentRoute: string;



  public usertype: any;
  submitted = false;
  showMsg: boolean = false;
  LoginFormGroup: FormGroup;

  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService) { 
     
  }
  

  ngOnInit() {

    this.name=localStorage.getItem('nameSub');
    this.customerId=localStorage.getItem('subscriber_id');
    

  }

  
  passChangeClick(){
    this.router.navigate(['/passwordChange']);
  }
}
