import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-payAmount',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PayComponent implements OnInit {
  
  username: string;
  password: string;
  public status: any;
  public usertype: any;
  submitted = false;
  showMsg: boolean = false;
  LoginFormGroup: FormGroup;

  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService) { 
    
  }

  ngOnInit() {
this. createloginForm();
  }

  createloginForm() {
    this.LoginFormGroup = this.fromBuilder.group({
      username:[''],
      password:['']
     
      
     
    });
  }
  loginClick(){
    this.submitted = true;
      this.username=this.LoginFormGroup.value['username'],
      this.password=this.LoginFormGroup.value['password']
  

    //if(this.username == "Superadmin" && this.password == "admin"){
      
      //this.router.navigate(['/dashboard']);
     
    //}else{
      
      let param = this.LoginFormGroup.value;
      // sessionStorage.setItem('pc', this.password);
   
      return this.httpServic.postServiceOfficialWithoutToken('user/login', param).subscribe(data=>{
        
        sessionStorage.setItem('user_type', data.user_type);
      this.usertype=data.user_type;
      if(this.usertype==1)
          {
           sessionStorage.setItem('agent_id', data.userprofile.userID);
           if(data.status==1)
            {
              this.showMsg= true;
          
                setTimeout(() => {
                  this.router.navigate(['/dashboard']);
                }, 1000);
           }
           else {
            alert("invalid User")
         
            this.router.navigate(['/login']);
                 }
            
          } 

       else
       if(this.usertype==2)
          {
            sessionStorage.setItem('subscriber_id', data.userprofile.userID);
            sessionStorage.setItem('token', data.userprofile.usertoken);
            sessionStorage.setItem('pass_changestatus', data.pass_changestatus);
            sessionStorage.setItem('pc', this.password);

           if(data.status==1 && data.pass_changestatus == 1 && data.pack_details.paid_status == 1)
           {
             this.showMsg= true;
              setTimeout(() => {
                 this.router.navigate(['/dashboardSubscriber']);
               }, 2000);
            }

            else if(data.status==1 && data.pass_changestatus == 0)
            {
              this.showMsg= true;
       
             setTimeout(() => {
               this.router.navigate(['/passwordChange']);
             }, 1000);
            }
            else if(data.status==1 && data.pass_changestatus == 1 && data.pack_details.paid_status != 1)
            {
              this.showMsg= true;
       
             setTimeout(() => {
               this.router.navigate(['/payAmount']);
             }, 1000);
            }

            
            }
            else
             {
              alert("invalid User/ Check Credentials")
              this.router.navigate(['/login']);
               }
         
          }
      );
    
   // }

 }
 goToRegistration(){
  this.router.navigate(['/registration']);
 }

}
