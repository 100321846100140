import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';

@Component({
  selector: 'app-agent-usermanagement',
  templateUrl: './agent-usermanagement.component.html',
  styleUrls: ['./agent-usermanagement.component.scss']
})
export class AgentUsermanagementComponent implements OnInit {
  public todayDate: any;
  addagentFormGroup: FormGroup;
  public status: any;
  public isAbroad: any;
  public sub_id:any;
  public user_type: any;
  public countryId: any;
  public countries: Array<any> = [];
  public packages: Array<any> = [];
  public states: Array<any> = [];
 public currently_employed=0;
  public package_id: any;
  submitted = false;
  //public checkValue=true;
  public agent_id: any;
  public payment_done: any;
  only_softcopy: number;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,) { 
    
  }

  ngOnInit() {
    this.createaddagentForm();
    this.listCountries();
    this.listStates();
    this.payment_done=0;
    this.isAbroad=0;
    this.only_softcopy=0;
    this.todayDate =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);
  }
  listCountries()
  {
    return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
      }
    });
  }
  OnChangeCountry(event)
  {
    console.log("country changed")
    this.countryId=event.target.value;
    this.listStates();
  }
  listStates(){
    let param = {
      country_id: this.countryId
    }
    return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 

  createaddagentForm() {
    this.addagentFormGroup = this.fromBuilder.group({
      name:['',Validators.required],
      gender: ['',Validators.required],
      dob:['',Validators.required],
      email:['',Validators.required],
      primary_mobile:['',Validators.required],
      whatsup_number:[''],
      landline_number:[''],
      currently_employed:[''],
      job_title:[''],
      company_name:[''],
      company_address:[''],
      housename:['',Validators.required],
      street:['',Validators.required],
      country_id:['',Validators.required],
      state_id:['',Validators.required],
      district:['',Validators.required],
      pincode:['',Validators.required],
      is_abroad:['',Validators.required],
      package_id:['',Validators.required],
     only_softcopy:[''],
      payment_done:[''] 
     
    });
  }
  onChangesoftcopy(e)
  {
    if(e.target.value==1)
    {
      this.only_softcopy=1
    }
    else{
      this.only_softcopy=0
    }
  }
  onChangepackage(e)
  {
    if(e.target.value==1)
    {
      this.isAbroad=1
    }
    else{
      this.isAbroad=0
    }
   
      
    let param = {
      is_abroad: this.isAbroad
    }
    console.log(param)
    return this.httpServic.postServiceOfficialWithoutToken('packages/listPackages', param).subscribe(data=>{
      console.log(data.packages)
      
      
      this.packages=data.packages
     });
    
    
  }
  
  
  
  showMsg: boolean = false;
  clear(){
    this.createaddagentForm();
    
  }
  submitagent(){
    this.status = 200;
    this.submitted = true;
    this.package_id=this.addagentFormGroup.value['package_id'];
    sessionStorage.setItem('package_id',this.package_id)
    this.user_type=sessionStorage.getItem('user_type')
    this.agent_id=sessionStorage.getItem('agent_id')
    console.log(this.user_type)
    if (this.addagentFormGroup.invalid) {
      return;
  }
    let param =
    {
      agent_id:this.agent_id,
      name:this.addagentFormGroup.value['name'],
     gender: this.addagentFormGroup.value['gender'],
      dob: this.addagentFormGroup.value['dob'],
      email: this.addagentFormGroup.value['email'],
      primary_mobile: this.addagentFormGroup.value['primary_mobile'],
      whatsup_number: this.addagentFormGroup.value['whatsup_number'],
      landline_number: this.addagentFormGroup.value['landline_number'],
      currently_employed: this.addagentFormGroup.value['currently_employed'],
      job_title : this.addagentFormGroup.value['job_title'],
      company_name : this.addagentFormGroup.value['company_name'],
      company_address : this.addagentFormGroup.value['company_address'],
      housename : this.addagentFormGroup.value['housename'],
      street : this.addagentFormGroup.value['street'],
      country_id : this.addagentFormGroup.value['country_id'],
      state_id : this.addagentFormGroup.value['state_id'],
      district : this.addagentFormGroup.value['district'],
      pincode : this.addagentFormGroup.value['pincode'],
     
      package_id:this.addagentFormGroup.value['package_id'],
      isabroad:this.addagentFormGroup.value['is_abroad'],
      soft_copy:this.only_softcopy,
      payment_done:this.payment_done
    } 
    console.log(param)
    if(this.user_type==1)
    {
      return this.httpServic.postServiceOfficialWithoutToken('user/registersubscriber', param).subscribe(data=>{
        console.log(data);
        if(data.status==0 && data.data==1)
        {
          alert("This Email already exist");
          return;
        }
        if(data.status==0 && data.data==0)
        {
          alert("This Phone no: already exist");
          return;
        }
        if(data.status==0 && data.data==2)
        {
          alert("This pincode is incorrect ");
          return;
        }
        sessionStorage.setItem('sub_ID', data.sub_ID);
        this.sub_id=data.sub_ID
        console.log(this.sub_id)
        this.showMsg= true;
        this.router.navigate(['/collection-details']);
      });
    }
   
    
    
  }
}
