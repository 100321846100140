import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-service';
import { ConstantsService } from 'src/app/services/constants.service';
import { UserService } from 'src/app/services/user-service';
import { DataService } from 'src/app/services/data-service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';


@Component({
  selector: 'app-edit-packages',
  templateUrl: './edit-packages.component.html',
  styleUrls: ['./edit-packages.component.scss']
})

export class EditPackagesComponent implements OnInit {
  private subscribeData: Subscription = null;
  public packagesDetails: any;
  public Valid_from: any;
  public Valid_to: any;

  addpackageFormGroup: FormGroup;
 
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService,
    private userService: UserService, 
    private dataService: DataService
   ) { }

    ngOnInit() {

    this.createaddpackageForm();
    if(this.dataService.getData()) {
      this.subscribeData = this.dataService.getData().subscribe(data => {
        if(data!=null) {
          this.packagesDetails = data;
         
          this.formFiller();
        }
      });
    }
  }

  createaddpackageForm() {
    this.addpackageFormGroup = this.formBuilder.group({
      package_name: [''],
      fees: [''],
      Valid_from: [],
      Valid_to: [],
      Description: [''],
      Start_month:[''],
      no_of_months:[''],
      is_abroad:[''],
      package_id:[]
    });
  }
  
  formFiller() {
    this.addpackageFormGroup.patchValue({
      package_name: this.packagesDetails.package_name,
      fees : this.packagesDetails.package_amount,

      Valid_from:this.packagesDetails.valid_from,
      Valid_to: this.packagesDetails.valid_to,

      Description: this.packagesDetails.package_description,
      Start_month:this.packagesDetails.start_month,
      no_of_months: this.packagesDetails.no_of_months,
      is_abroad: this.packagesDetails.is_abroad,
      package_id: this.packagesDetails.package_id,

      });
     
      
       this.Valid_from =new Date(this.packagesDetails.valid_from);
      this.Valid_to =new Date(this.packagesDetails.valid_to);
    
     
  }

  onEditPackage() {
    if(!this.addpackageFormGroup.invalid) {
      let param = this.addpackageFormGroup.value;
      
      return this.httpServic.patchServiceOfficial('packages/packageedit', param).subscribe(data=>{
        this.router.navigateByUrl('packages');
      
      
      });

    }
  }
  onCancelClick()
  {
    this.router.navigateByUrl('packages');
  }
}
