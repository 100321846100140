import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { DataService } from '../services/data-service';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit {
  
  public isAbroad: any;
  public package_id:any;
  public usertype:any;
  public packagesAbroadList: Array<any> = [];
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,){ }

  ngOnInit() {
    this.isAbroad=0;
    this.listpackagesAbroad();
    this.usertype=sessionStorage.getItem('user_type')
  }
  //packages : Packages[];

  AddpackageClick(){
    this.router.navigate(['/packages/add-packages']);
}

onEditPackageClick(data){

  this.dataService.setData(data);
  this.router.navigate(['/packages/edit-packages']);
}

deletePackage(data)
{
 
  
  return this.httpServic.deleteServiceOfficial('packages/packagedelete/'+data.package_id).subscribe( data=>{
    this.listpackagesAbroad();
  });

 


}
onChangeCheckBox(event){
 
  if(event.target.checked == true){
    this.isAbroad = 1;
   
  }else{
   this.isAbroad = 0;
   
  }
  this.listpackagesAbroad();
}


listpackagesAbroad(){
  let param = {
    is_abroad: this.isAbroad
  }
  return this.httpServic.postServiceOfficialWithoutToken('packages/listPackages', param).subscribe(data=>{
   
    if(data.status==1 && data.packages.length>0 && data.packages != undefined){
      this.packagesAbroadList = data.packages;
      //console.log(this.packagesAbroadList);
    }
  });
   
} 

//Within India
listpackages(){
  return this.httpServic.getServiceOfficial('packages/listPackages').subscribe(data=>{
    
  });
   
} 

}
