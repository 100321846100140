import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
 isLoggedIn=false;
  constructor() { 
    
  }
   
}
