import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';

@Component({
  selector: 'app-admin-usermanagement',
  templateUrl: './admin-usermanagement.component.html',
  styleUrls: ['./admin-usermanagement.component.scss']
})
export class AdminUsermanagementComponent implements OnInit {

  addagentFormGroup: FormGroup;
  public status: any;
  public todayDate: any;
  public user_type: any;
  public countryId: any;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public currently_employed=true;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,) { 
    
  }

  ngOnInit() {
    this.createaddagentForm();
    this.listCountries();
    this.listStates();
    this.todayDate =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);

    
  }
  listCountries()
  {
    return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
      }
    });
  }
  OnChangeCountry(event)
  {
    
    this.countryId=event.target.value;
    this.listStates();
  }
  listStates(){
    let param = {
      country_id: this.countryId
    }
    return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 

  createaddagentForm() {
    this.addagentFormGroup = this.fromBuilder.group({
      user_type:[''],
      name:[''],
      gender: [''],
      dob:[''],
      email:[''],
      primary_mobile:[''],
      whatsup_number:[''],
      landline_number:[''],
      currently_employed:[''],
      job_title:[''],
      company_name:[''],
      company_address:[''],
      housename:[''],
      street:[''],
      country_id:[''],
      state_id:[''],
      district:[''],
      pincode:['']
     
    });
  }
  OnNotEmployedClick(value)
  {
    alert(value);
    if(value==true)
    {
      this.currently_employed=false;
    }
  }
  showMsg: boolean = false;
  submitagent(){
    this.status = 200;
    this.user_type=this.addagentFormGroup.value['user_type'];

    
    let param = this.addagentFormGroup.value;
    if(this.user_type==2)
    {
      return this.httpServic.postServiceOfficialWithoutToken('user/register', param).subscribe(data=>{
        
        this.showMsg= true;
        this.router.navigate(['/agents']);
      });
    }
    else if(this.user_type==1)
    {
      return this.httpServic.postServiceOfficialWithoutToken('user/registeradmin', param).subscribe(data=>{
       
        this.showMsg= true;
        
      });

    }
    
    
  }
  onCancelClick()
 {
   this.router.navigateByUrl('agents');
 }

}
