import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './services/user-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public userDetils: any;
  public userType: any;
  title = 'Sevasram-Admin';

  constructor(private router: Router,
    private userService: UserService){}

    ngOnInit(){
      // if(this.userService.getUserDetails()!=null){
      //   console.log(this.userService.getUserDetails());
      //   this.userDetils = this.userService.getUserDetails();
      //   this.userType = this.userDetils.username;
      //   console.log(this.userType);
      //   //this.userService.setUserDetails(null);
      // }


      

    }
}
