import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { HttpClientService } from 'src/app/services/http-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.scss']
})
export class AddPackagesComponent implements OnInit {

  addpackageFormGroup: FormGroup;
  submitted = false;
  public status: any;
  public allBooks: [];
  public checkValue=false;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,) { 
    
  }

  ngOnInit() {
    this.createaddpackageForm();
    this.listBooks();
     
  }

listBooks()
{
  return this.httpServic.getServiceOfficial('packages/listBooks').subscribe(data=>{
   
    this.allBooks=data.packages;
  });
}
  createaddpackageForm() {
    this.addpackageFormGroup = this.fromBuilder.group({
      package_name: ['',Validators.required],
       book_id:['',Validators.required],
      fees: ['',Validators.required],
      Valid_from: ['',Validators.required],
      Valid_to: ['',Validators.required],
      Description: ['',Validators.required],
      Start_month:['',Validators.required],
      no_of_months:['',Validators.required],
      is_abroad:[]
    });
  }
  showMsg: boolean = false;
  submitpackage(){
    this.submitted = true;
  if (this.addpackageFormGroup.invalid) {
    return;
}
    this.status = 200;
    let param = this.addpackageFormGroup.value;


    // if(this.status==200){
    //   this.userService.setUserDetails(addpackage);
    //   this.router.navigate(['/dashboard']);
    //   //this.userService.setUserDetails(userData);
    // }

    
    return this.httpServic.postServiceOfficialWithoutToken('packages/Addpackages', param).subscribe(data=>{
      
      this.showMsg= true;
     

      setTimeout(() => {
        this.router.navigateByUrl('packages');
      }, 2000);
      
    //   setTimeout(() => {
    //     this.router.navigate(['dashboard']);
    // }, 2000);
    //this.router.navigateByUrl('dashboard');
    
    });
     
 }  
 onCancelClick()
 {
   this.router.navigateByUrl('packages');
 }
}
