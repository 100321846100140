import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/services/http-service';
import { ConstantsService } from 'src/app/services/constants.service';
import { UserService } from 'src/app/services/user-service';
import { DataService } from 'src/app/services/data-service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-books',
  templateUrl: './edit-books.component.html',
  styleUrls: ['./edit-books.component.scss']
})
export class EditBooksComponent implements OnInit {
  editBooksFormGroup: FormGroup;
  private subscribeData: Subscription = null;
  public Bookdetails: any;
  public published_date: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService,
    private userService: UserService, 
    private dataService: DataService) { }

  ngOnInit() {
    this.createeditBooksForm();
    if(this.dataService.getData()) {
      this.subscribeData = this.dataService.getData().subscribe(data => {
        if(data!=null) {
          this.Bookdetails = data;

          this.formFiller();
        }
      });
    }
  }

  createeditBooksForm() {
    this.editBooksFormGroup = this.formBuilder.group({
      title: [''],
      description: [''],
      published_date:[''],
      book_id:[]
      
    });
  }
  
  formFiller() {
    this.editBooksFormGroup.patchValue({
      title: this.Bookdetails.title,
      description : this.Bookdetails.description,
      published_date : moment(this.Bookdetails.published_date).format('YYYY-MM-DD'),
      book_id : this.Bookdetails.book_id,
     
     
    });
    this.published_date=new Date(this.Bookdetails.published_date);
  }
  showMsg: boolean = false;
  submitbooks() {
    if(!this.editBooksFormGroup.invalid) {
      let param = this.editBooksFormGroup.value;
      
      return this.httpServic.patchServiceOfficial('packages/bookedit', param).subscribe(data=>{
        this.router.navigateByUrl('/books');
        this.showMsg= true;
      });

    }
  }
  onCancelClick()
  {
    this.router.navigateByUrl('/books');
  }


}
