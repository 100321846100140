import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {

  ListBooks :any;  
  usertype :any;  

  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,){ }

  ngOnInit() {
    this.listbooks();
   this.usertype=sessionStorage.getItem('user_type')

  }

  AddBooksClick(){
    this.router.navigate(['/books/add-books']);
  }
  EditBooksClick(data){
    
   
    this.dataService.setData(data);
    this.router.navigate(['/books/edit-books']);
  }
  deletebooks(data)
  {
    
  return this.httpServic.deleteServiceOfficial('packages/bookdelete/'+data.book_id).subscribe( data=>{
    this.listbooks();
  });
  }
 

  listbooks(){
    return this.httpServic.getServiceOfficial('packages/listBooks').subscribe(data=>{
   
      if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        this.ListBooks = data.packages;
        
      }
    });
     
  } 
}
