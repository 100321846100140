import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UserService } from './user-service';

@Injectable({
	providedIn: 'root'
})
export class HttpClientService {

	baseUrlAdmin: string = "https://admin.sevasram.org/api/"
	httpClient: any;
    // baseUrlAdmin: string = "http://52.55.205.218:5555/"
    //   baseUrlAdmin: string = "http://127.0.0.1:3000/"
      baseUrlPayment: string = "https://payment.sevasram.org/"
      baseUrlNews: string = "http://sevasram.org/index.php/"



	
	constructor(private http: HttpClient,
		private userService: UserService) { }

	/**
   * 
   * @param url 
   * @param params 
   */
	postServiceOfficialWithoutToken(url: string, params: object): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*'
		});
	
		return this.http.post<any>(this.baseUrlAdmin + url, params, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}

// /**
// 	 * 
// 	 * @param url 
// 	 * @param params 
// 	 */

	// postFileUpload(fileToUpload: File): Observable<boolean> {
	// 	const endpoint = 'http://localhost:3000/';
	// 	const formData: FormData = new FormData();
	// 	formData.append('fileKey', fileToUpload, fileToUpload.name);
	// 	return this.httpClient
	// 	  .post(endpoint, formData, { headers: Headers })
	// 	  .map(() => { return true; })
	// 	  .catch((e) => this.handleError(e));
	// }
	// handleError(e: any) {
	// 	throw new Error("Method not implemented.");
	// }

	uploadFileServiceOfficial(url: string, formData: FormData): Observable<any> {

		let headers = new HttpHeaders({
		  'Access-Control-Allow-Origin': '*',
		  'Access-Control-Allow-Methods': '*'
		});
	
		return this.http.post<any>(this.baseUrlAdmin + url, formData, { headers: headers })
		  .pipe(
			map(data => {
			  return data;
			}));
	  }
	
	uploadFileService(url: string, formData: FormData): Observable<any> {
		
		return this.http.post<any>(this.baseUrlAdmin + url, formData)
		  .pipe(
			map(data => {
			  return data;
			}));
  }
	/**
	 * 
	 * @param url 
	 */
	getServiceOfficial(url: string): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin':'*',
			'Access-Control-Allow-Methods':'*'
		});
		return this.http.get<any>(this.baseUrlAdmin + url, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}
	postServiceOfficialWithoutTokenNews(url: string): Observable<any> {

		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin':'*',
			'Access-Control-Allow-Methods':'*'
		});
		return this.http.get<any>(this.baseUrlNews + url, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}

	/**
	 * 
	 * @param url 
	 * @param params 
	 */
	postServiceOfficial(url: string, params: object): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.post<any>(this.baseUrlAdmin + url, params, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}
	


	/**
	 * 
	 * @param url 
	 * @param params 
	 */
	putServiceOfficial(url: string, params: object): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.put<any>(this.baseUrlAdmin + url, params, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}

	/**
	 * 
	 * @param url 
	 * @param params 
	 */
	patchServiceOfficial(url: string, params: object): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.patch<any>(this.baseUrlAdmin + url, params, { headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
	}

	/**
	 * 
	 * @param url
	 * @param params  
	 */
	deleteServiceOfficial(url: string): Observable<any> {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		if (window.confirm('Are you sure, you want to delete?')){
		return this.http.delete<any>(this.baseUrlAdmin + url,{ headers: headers })
			.pipe(
				map(data => {
					return data;
				}))
			}
	}
}


