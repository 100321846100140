import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss','./dashboard.component.scss']
 
})
export class DashboardComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public userDetils: any;
  public totalSubscribed: any;
  public inactiveusers: any;
  public amountreceived: any;
  public totalagent: any;
  public abroadSubscribedCount: any;
  public abroadSubscribedYear: any;
  public indianSubscribedCount: any;
  public indianSubscribedYear: any;
  public chartReady:any;
  public lineChartIndianData: ChartDataSets[];
  public lineChartAbroadData: ChartDataSets[];
  public lineChartIndianLabels: Label[];
  public lineChartAbroadLabels: Label[];
  lineChartOptions = {
    responsive: true,
  };
 
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,0,128,0.28)',
    },
  ];
  lineChartAbroadColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,128,0,0.12)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    ){
      this.bindChart();
     }

  ngOnInit() {
  
    this.getdata();
    this.listsubscribers();
   
    
  }
bindChart()
{
  this.userType=sessionStorage.getItem('user_type');
  this.agent_id=sessionStorage.getItem('agent_id');
  if(this.userType==1)
  {
    let param= {
      user_id:this.agent_id
    }
    return this.httpServic.postServiceOfficialWithoutToken('dashboard/agentchartDetails',param).subscribe(data=>{
   
      this.abroadSubscribedCount = data.abroadSubscribedCount;
      this.abroadSubscribedYear = data.abroadSubscribedYear;
      this.indianSubscribedCount = data.indianSubscribedCount;
      this.indianSubscribedYear = data.indianSubscribedYear;
      this.lineChartIndianData= [
        { data: this.indianSubscribedCount, label: 'Indian Subscribers' },
      ];
      this.lineChartAbroadData = [
        { data: this.abroadSubscribedCount, label: 'Abroad Subscribers' },
      ];
      this.lineChartIndianLabels = this.indianSubscribedYear;
      this.lineChartAbroadLabels = this.abroadSubscribedYear;
      this.chartReady = true;
     
  });
  }
  else
  {
    return this.httpServic.getServiceOfficial('dashboard/adminchartDetails').subscribe(data=>{
   
      this.abroadSubscribedCount = data.abroadSubscribedCount;
      this.abroadSubscribedYear = data.abroadSubscribedYear;
      this.indianSubscribedCount = data.indianSubscribedCount;
      this.indianSubscribedYear = data.indianSubscribedYear;
      this.lineChartIndianData= [
        { data: this.indianSubscribedCount, label: 'Indian Subscribers' },
      ];
      this.lineChartAbroadData = [
        { data: this.abroadSubscribedCount, label: 'Abroad Subscribers' },
      ];
      this.lineChartIndianLabels = this.indianSubscribedYear;
      this.lineChartAbroadLabels = this.abroadSubscribedYear;
      this.chartReady = true;
      
  });
  }
 
}

  getdata(){

    this.userType=sessionStorage.getItem('user_type');
   this.agent_id=sessionStorage.getItem('agent_id');
    
    let param= {
      user_id:this.agent_id
    }
    if(this.userType==1)
    {
    
     return this.httpServic.postServiceOfficialWithoutToken('dashboard/getDetails', param).subscribe(data=>{
        this.totalSubscribed=data.count.totalSubscribed;
        this.amountreceived=data.count.amountreceived;
        this.inactiveusers=data.count.inactiveusers;
        
         
      });
    }
    else{ 
      return this.httpServic.getServiceOfficial('dashboard/admindashboaddetails').subscribe(data=>{
     
      this.totalSubscribed=data.count.totalSubscribed;
      this.amountreceived=data.count.amountreceived;
      this.inactiveusers=data.count.inactiveusers;
      this.totalagent=data.count.totalagent;
      
     });
    }
     
  }

  listsubscribers(){

    let param= {
      user_id:this.agent_id
    }
    if(this.userType==1)
    {
      return this.httpServic.postServiceOfficialWithoutToken('subscribers/subscriberlist', param).subscribe(data=>{
       
        this.Listsubscribers = data.subscribers;
       
      });
    }
    else{
    return this.httpServic.getServiceOfficial('dashboard/subscriberlistadmin').subscribe(data=>{
   
      // if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        this.Listsubscribers = data.subscribers;
      
      //}
    });
  }
     
  } 
   
     
  //} 

}
