import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['../app.component.scss','./notification.component.scss']
 
})
export class NotificationComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public notidetails: any;
  public notidata: any;
  public gen: any;
  public packName: any;
  public packExp: any;
  public subsName: any;
  public subsId:any;





 

  public userDetailsArray: Array<any> = [];




 
  
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    ){
    

      
     }

  ngOnInit() {
  
    this.getdata();
    this.gen=sessionStorage.getItem('gender');
    this.subsName=sessionStorage.getItem('name');
    this.packExp=sessionStorage.getItem('packExp');
    this.packName=sessionStorage.getItem('packName');
    this.subsId=sessionStorage.getItem('subscriber_id');
    





   
    
  }
  


  getdata(){

   this.agent_id=sessionStorage.getItem('subscriber_id');
    
    let param= {
      user_id:this.agent_id,
      user_type:2
    }
 
    
    
     return this.httpServic.postServiceOfficial('notification/listnotification', param)
     .subscribe(data=>{
      //  console.log(data);
         if(data.status==1){
          //  console.log(data);
            this.notidetails = data;
            // console.log(this.notidetails);
            this.notidata = data.notification_data;
            // console.log(this.notidata);
            
          }
          else {
            this.notidetails= null;

          }
      
         
      

        
         
      });
    
     
  }
  
  
     
  
   
     
  

}
