import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClientService } from '../services/http-service';
import { DataService } from '../services/data-service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  reportFormGroup: FormGroup;
  //printFormGroup: FormGroup;
  public isAbroad: any;
  public countryId: any;
  public zoneid: any;
  public packagesAbroadList: Array<any> = [];
  public packagesAllList: Array<any> = [];
  public countries: Array<any> = [];
  public zones: Array<any> = [];
  public states: Array<any> = [];
  public reportResults: Array<any> = [];
  //public printResults: Array<any> = [];

  showMsg: boolean = false;
 
  title = 'angulardatatables';
  //dtOptions: any = {};
  constructor(  private dataService: DataService,private router: Router,
    private httpServic: HttpClientService,
    private formBuilder: FormBuilder,) { }

  ngOnInit() {
    this.createReportForm();
   // this.createprintForm();
    this.listCountries();
    this.listAllPackages();
    this.listZones();
    // this.dtOptions = {
    
    //   pagingType: 'full_numbers',
    //   pageLength: 5,
    //   processing: true,
    //   dom: 'Bfrtip',
    //   buttons: ['copy','csv','excel','print']
      
    // };
  }
  createReportForm()
  {
    this.reportFormGroup = this.formBuilder.group({
      is_abroad:[''],
      package_id:[''],
      start_date:[''],
      end_date:[''],
      is_active:[''],
      country_id:[''],
      state_id:[''],
      zone_id:['']
     
    });
  }
  // createprintForm()
  // {
  //   this.printFormGroup = this.formBuilder.group({
  //     user_id:['']
     
     
  //   });
  // }
  onChangeAbroadCheckBox(event){
    if(event.target.checked == true){
      this.isAbroad = 1;
     
    }else{
     this.isAbroad = 0;
     
    }
    this.listpackagesAbroad();
  }

  OnChangeCountry(event)
  {
    
    this.countryId=event.target.value;
    this.listStates();
  }

  listAllPackages()
  {
    return this.httpServic.getServiceOfficial('packages/listAllPackages').subscribe(data=>{
      
      if(data.status==1 && data.packages.length>0 && data.packages != undefined){
         this.packagesAllList = data.packages;
       }
     });
  }

  listpackagesAbroad(){
    let param = {
      is_abroad: this.isAbroad
    }
    return this.httpServic.postServiceOfficialWithoutToken('packages/listPackages', param).subscribe(data=>{
     if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        this.packagesAbroadList = data.packages;
      }
    });
     
  } 
  
  listCountries()
  {
    return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
      }
      
    });
   
  }
  listStates(){
    let param = {
      country_id: this.countryId
    }
    return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 

  listZones(){
    let param = {
      zone_id: this.zoneid
    }
    return this.httpServic.getServiceOfficial('masters/listzones').subscribe(datazones=>{
     
     if(datazones.status==1 && datazones.data.length>0 && datazones.data != undefined){
        this.zones = datazones.data;
      }
    
    });
     
  } 

  submitReport(){
   
    this.reportResults = [];
    let param = this.reportFormGroup.value;
    return this.httpServic.postServiceOfficialWithoutToken('subscribers/reportResults', param).subscribe(data=>{
    if(data.status==1 && data.subscribers.length>0 && data.subscribers != undefined){
        this.reportResults = data.subscribers;
        
      }
    });
 }
 
//  printwrapper(){
   
//   this.printResults = [];
//   let param = this.printFormGroup.value;
//   console.log(param)
//   return this.httpServic.postServiceOfficialWithoutToken('subscribers/subscriberlocation', param).subscribe(data=>{
//   //if(data.status==1 && data.subscribers.length>0 && data.subscribers != undefined){
//       this.printResults = data.location;
     
//     //}
//     console.log(this.printResults);
   
//   });
// }
 onCancelClick()
 {
   this.router.navigateByUrl('dashboard');
 } 
}
