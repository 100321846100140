import { Component, OnInit } from '@angular/core';

  import { Router } from '@angular/router';
  import { UserService } from '../services/user-service';
  import { DataService } from '../services/data-service';
  import { HttpClientService } from '../services/http-service';

@Component({
  selector: 'app-renewlist',
  templateUrl: './renewlist.component.html',
  styleUrls: ['./renewlist.component.scss']
})
export class RenewlistComponent implements OnInit {
    public renewlist: Array<any> = [];
    Listagents :any;  
    agent_id: string;
    constructor(private router: Router, 
      private userService: UserService, 
      private dataService: DataService,
      private httpServic: HttpClientService,){ }
  
      ngOnInit() {
        this.listexpiredusers();
      }
  
      onrenewclick(data:any){
     console.log(data);
     this.dataService.setData(data);    
      this.router.navigate(['/renewlist/renew']);
     
    }
  

  
    listexpiredusers(){
        this.agent_id=sessionStorage.getItem('agent_id')
        let param = {
            user_id: this.agent_id
          }
        return this.httpServic.postServiceOfficialWithoutToken('payment/inactiveusers', param).subscribe(data=>{
            console.log(data);
            this.renewlist = data.subscribers;
           
      
           
        });
          
    } 
       
    
 }

  
