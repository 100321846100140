import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './passwordChange.component.html',
  styleUrls: ['../app.component.scss','./passwordChange.component.scss']
 
})
export class PasswordChangeComponent implements OnInit {
  Listsubscribers :any;  
  public dataObserver: Observer<any>;
  public currentPassword;
  public newPassword;
  public confirmPassword;
  public discription;
  public isDisable=false;
  public isDisabled=false;
  public showMsg=false;


  public message;
  public confirm_status;
  public errormsg1;
  public errormsg2;
  public errormsg3;
  public userData;
  public userId; 
  public pc; 
  public user_id; 
  public passTrue=false;
  public userType: any;
  
  
  lineChartOptions = {
    responsive: true,
  };
 
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,0,128,0.28)',
    },
  ];
  lineChartAbroadColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,128,0,0.12)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    ){
      // this.bindChart();
     }

  ngOnInit() {
    this.pc=sessionStorage.getItem("pc");
    this.user_id = sessionStorage.getItem("subscriber_id")
    // console.log(this.pc);
  
    // this.getdata();
    // this.listsubscribers();
   
    
  }

     
  submitModal() {
    // console.log("currentpass",this.currentPassword);
    if (this.currentPassword == undefined || this.currentPassword == "")  
    {
     this.errormsg1 =
         "You can't leave this empty"
    }
    else 
      if (this.currentPassword != undefined || this.currentPassword != "")
          
      {
        if (this.currentPassword == this.pc)
            {
              this.errormsg1 =
                  ""
              
            }
            else if (this.currentPassword != this.pc)
              {
                this.errormsg1 =
                  "current password is wrong"
              }
  }
  if (this.newPassword == undefined || this.newPassword == ""){
    this.errormsg2 =
    "You can't leave this empty"
  }
  else
  {this.errormsg2 ="" }

  if (this.confirmPassword == undefined || this.confirmPassword == ""){
    this.errormsg3 =
    "You can't leave this empty"
  }
  else
  {this.errormsg3 ="" }

  if (this.isDisable == true && this.isDisabled==true ){
   
    
    let param= {
      userid:this.user_id,
      oldpassword:this.currentPassword,
      newpassword:this.confirmPassword
    }
    
    return this.httpServic.postServiceOfficialWithoutToken('user/changepassword', param)
     .subscribe(data=>{
      //  console.log(data);
      //  console.log(param);
       if(data.status==1)
            {
              this.showMsg= true;
              alert("Password changed successfully, Please login with the new password")
          
                setTimeout(() => {
                  this.router.navigate(['/login']);
                }, 3000);
           }
           else {
            alert("Something went wrong")
         
            this.router.navigate(['/login']);
                 }
      });

    
  }
}
 
  passwordValidation = () => {
		if (
			!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})/.test(
				this.newPassword
			)
		) {
      this.errormsg2 = ""
			this.discription =
				"Password must contain 1 upper case, 1 Lower case, 1 special key, 1 digit and must contain at least 8 character!";
		} else if (this.newPassword.length < 8) {
			this.discription = "Minimum 8 char";
		} else if (this.newPassword.length > 15) {
			this.discription = "Password can not be exceed than 15";
		} else if (this.newPassword.length == 0) {
			this.discription = "This feild can not be empty!";
		} else {
			this.discription = "Perfect Password";
			this.message = "";
		}

		if (this.discription == "Perfect Password") {
			this.isDisable = true;
		} else {
			this.isDisable = false;
		}

		return this.isDisable;
	};
  comparePassword = () => {
		if (this.newPassword != this.confirmPassword) {
      this.errormsg3 =""
      this.isDisabled=false;
			this.confirm_status =
				"Confirm password is not match with New password";
     
       
			return false;
		} else {
      this.isDisabled = true;
			this.confirm_status = "";
      
			return true;
		}
	};
  

}
