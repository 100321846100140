import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inner',
  templateUrl: './inner.component.html',
  styleUrls: ['./inner.component.scss']
})
export class InnerComponent implements OnInit {
  public userType: any;
  constructor() { }

  ngOnInit() {

   
    this.userType =  sessionStorage.getItem('user_type')



  }

}
