import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './afterPayment.component.html',
  styleUrls: ['../app.component.scss','./afterPayment.component.scss']
 
})
export class AfterPaymentComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public userDetils: any;
  public transactiondata: any;
  public userdata: any;
  public name:any;
  public transaction_id:any;

  loaded: boolean = false;



  
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    private http: HttpClient
    ){
      // this.bindChart();
     }

  ngOnInit() {
  
    this.getdata();
    this.transaction_id=localStorage.getItem('transaction_id');
    this.name=localStorage.getItem('nameSub');

    // this.pack_expire=localStorage.getItem('pack_expire');




    // this.listsubscribers();
   
    
  }


 
 


  getdata(){
   this.transaction_id=localStorage.getItem('transaction_id');

    
    let param= {
      trans_id:this.transaction_id
    }
   
     return this.httpServic.postServiceOfficial('payment/getTransactionStatus', param)
     .subscribe(data=>{
       console.log(data);
        this.transactiondata=data;

        
        
         
      });
    
   
     
  }
 
    


     
  
   
  loginAgain(){
    this.router.navigate(['/login']);

  }

}
