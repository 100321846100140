import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from '../services/http-service';
import { UserService } from '../services/user-service';

@Component({
  selector: 'app-collection-details',
  templateUrl: './collection-details.component.html',
  styleUrls: ['./collection-details.component.scss']
})
export class collectiondetailsComponent implements OnInit {

  

  collectiondetailsFormGroup: FormGroup;
    submitted = false;
    public status: any;
  public sub_id: any;
  public agent_id: any;
  public package_id: any;
  public collection_mode: any;
    
    constructor(private router: Router, 
      private userService: UserService, 
      private httpServic: HttpClientService,
      private fromBuilder: FormBuilder,) { 
      
    }
  
    ngOnInit() {
      this.createcollectiondetails();
      
       
    }
  
  
    createcollectiondetails() {
      this.collectiondetailsFormGroup = this.fromBuilder.group({
        collection_mode: ['',Validators.required],
        chequeno:[''],
        amount: ['',Validators.required],
        collected_date: ['',Validators.required],
        
      });
    }
    onChangecollectionmode(event)
  {
    console.log(event.target.value);
    this.collection_mode=event.target.value
  }
    showMsg: boolean = false;
    
    submit(){
      this.submitted = true;
    if (this.collectiondetailsFormGroup.invalid) {
      return;
  }
      this.status = 200;
      this.sub_id=sessionStorage.getItem('sub_ID')
      this.agent_id=sessionStorage.getItem('agent_id')
      this.package_id=sessionStorage.getItem('package_id')
      if(this.collection_mode==2 && (this.collectiondetailsFormGroup.value['chequeno'] == undefined ||this.collectiondetailsFormGroup.value['chequeno'] == "")){
        alert("Please Enter the cheque number");
      }
      else{
        let param = {
          coll_mode_id: this.collection_mode,
          cheque_dd_no:this.collectiondetailsFormGroup.value['chequeno'],
          amount:this.collectiondetailsFormGroup.value['amount'],
          collected_date:this.collectiondetailsFormGroup.value['collected_date'],
          agent_id: this.agent_id,
          user_id: this.sub_id,
          package_id: this.package_id
        }
       // let param = this.collectiondetailsFormGroup.value;
       console.log(param);
       //this.username=this.LoginFormGroup.value['username'],
        
        
        return this.httpServic.postServiceOfficialWithoutToken('payment/recordPayment', param).subscribe(data=>{
          console.log(data);
          this.showMsg= true;
         
    
          setTimeout(() => {
            this.router.navigateByUrl('dashboard');
          }, 2000);
          
       
        sessionStorage.removeItem('sub_ID');
        sessionStorage.removeItem('package_id');
        });

      }

     
      
   }  
   onCancelClick()
   {
     this.router.navigateByUrl('packages');
   }
  }
  
