import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { HttpClientService } from 'src/app/services/http-service';
import { DatePipe } from '@angular/common'


@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.scss']
})
export class AddAgentComponent implements OnInit {

  addagentFormGroup: FormGroup;
  public status: any;
  public user_type: any;
  public todayDate: any;

  public agent_id:any;
  public isAbroad: any;
  public sub_id:any;
  public countryId: any;
  public packages: Array<any> = [];
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public soft_copy: number;
  public no_check_email: number;
public payment_done=0;
  submitted = false;
 public currently_employed=0;
  public is_agent = false;
  public mobilerequired = false;
  public emailrequired = false;

 // public checkValue=true;
  public checkValue1=false;
  username: any;
  randomnum:any;
  str:any;
  date:any;
  password: string;
  
  //public checkValue=true;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,
    public datepipe: DatePipe) { 
    
  }

  ngOnInit() {
    this.createaddagentForm();
    this.listCountries();
    this.listStates();
this.isAbroad=0;
this.soft_copy=0;
this.no_check_email=0;
this.payment_done=0;
this.todayDate =
new Date().getFullYear() +
"-" +
("0" + (new Date().getMonth() + 1)).slice(-2) +
"-" +
("0" + new Date().getDate()).slice(-2);
    
  }
  listCountries()
  {
    return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
      }
    });
  }
  OnChangeCountry(event)
  {
    
    this.countryId=event.target.value;
    this.listStates();
  }
  onChangeUserType(event)
  {
    this.user_type=this.addagentFormGroup.value['user_type'];
    console.log(this.user_type);
    if (this.user_type == 1) { 
      this.addagentFormGroup.controls['primary_mobile'].setValidators([Validators.required]);;
    }
    else{
      this.addagentFormGroup.controls['primary_mobile'].clearValidators();


    }
    // if(event.target.value=="2")
    // {
    //   this.is_agent=true;
    // }
    // else
    // {
    //   this.is_agent=false;
    // }
  }
  listStates(){
    let param = {
      country_id: this.countryId
    }
    return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 

  createaddagentForm() {
    this.addagentFormGroup = this.fromBuilder.group({
      user_type:[''],
      name:['',Validators.required],
      gender: ['',Validators.required],
      dob:['',Validators.required],
      email:['' ],
      primary_mobile:[''],
      whatsup_number:[''],
      landline_number:[''],
      currently_employed:[''],
      job_title:[''],
      company_name:[''],
      company_address:[''],
      housename:['',Validators.required],
      street:['',Validators.required],
      country_id:['',Validators.required],
      state_id:['',Validators.required],
      district:['',Validators.required],
      pincode:['',Validators.required],
      is_active:[''],
      isabroad:[''],
      package_id:[''],
      soft_copy:[''],
      no_check_email:[''],
      payment_done:[''],
      username:[''],
      password:['']

     
    });
  }
  // onCheckChange(value) {
  //   var is_active= this.addagentFormGroup.get('is_active') as any;
  //   is_active=value;
    
  // }
  
  // OnNotEmployedClick(value)
  // {
  //   alert(value);
  //   if(value==true)
  //   {
  //     this.currently_employed=false;
  //   }
  // }
  onChangesoftcopy(e)
  {
    if(e.target.value==1)
    {
      this.soft_copy=1
    }
    else{
      this.soft_copy=0
    }
  }

  onChangeemail(event)
  {

    this.randomnum=Math.floor((Math.random() * 100) + 1)

    

    if(event==true)
    {
     
      var str=this.addagentFormGroup.value['dob']


      this.date=this.datepipe.transform(str, 'dd-MM-yyyy')
     
       

      this.password = this.date.replaceAll('-', '');
      console.log(this.password)

     this.username=this.addagentFormGroup.value['name'].replace(/\s/g, "")+this.randomnum+'@sevasram.org'
   
     console.log(this.addagentFormGroup.value['username'])
    }
    else{
     

    }
  }
  onChangepackage(e)
  {
    if(e.target.value==1)
    {
      this.isAbroad=1
    }
    else{
      this.isAbroad=0
    }
      
    let param = {
      is_abroad: this.isAbroad
    }
    console.log(param)
    return this.httpServic.postServiceOfficialWithoutToken('packages/listPackages', param).subscribe(data=>{
      console.log(data.packages)
      
      
      this.packages=data.packages
     });
    
    
  }
  
  showMsg: boolean = false;
  submitagent(){
    console.log("1",this.mobilerequired);

    if(this.user_type==1 && this.addagentFormGroup.value['primary_mobile']== "" ){
      this.mobilerequired=true;
      console.log("2",this.mobilerequired);
    }
    else{
      this.mobilerequired=false;

    }
    if(this.user_type==1 && this.addagentFormGroup.value['email']== "" ){
      this.emailrequired=true;
      console.log("2",this.emailrequired);
    }
    else{
      this.emailrequired=false;

    }


    this.status = 200;
    this.submitted = true;
    this.user_type=this.addagentFormGroup.value['user_type'];

    console.log(this.user_type)
    if (this.addagentFormGroup.invalid) {
      return;
  }

    
    
    if(this.user_type==2)
    {
      //let param = this.addagentFormGroup.value;
      let param =
      {
        
        name:this.addagentFormGroup.value['name'],
       gender: this.addagentFormGroup.value['gender'],
        dob: this.addagentFormGroup.value['dob'],
        email: this.addagentFormGroup.value['email'],
        primary_mobile: this.addagentFormGroup.value['primary_mobile'],
        whatsup_number: this.addagentFormGroup.value['whatsup_number'],
        landline_number: this.addagentFormGroup.value['landline_number'],
        currently_employed: this.addagentFormGroup.value['currently_employed'],
        job_title : this.addagentFormGroup.value['job_title'],
        company_name : this.addagentFormGroup.value['company_name'],
        company_address : this.addagentFormGroup.value['company_address'],
        housename : this.addagentFormGroup.value['housename'],
        street : this.addagentFormGroup.value['street'],
        country_id : this.addagentFormGroup.value['country_id'],
        state_id : this.addagentFormGroup.value['state_id'],
        district : this.addagentFormGroup.value['district'],
        pincode : this.addagentFormGroup.value['pincode'],
        no_check_email:this.addagentFormGroup.value['no_check_email'],
        username:this.username,
        password:this.password
       
      } 
    console.log(param);
      return this.httpServic.postServiceOfficialWithoutToken('user/register', param).subscribe(data=>{
        console.log(data);
        
        // if(data.status==0 && data.data==1)
        // {
        //   alert("This Email already exist");
        //   return;
        // }
        // if(data.status==0 && data.data==0)
        // {
        //   alert("This Phone no: already exist");
        //   return;
        // }
        if(data.status==0 && data.data==2)
        {
          alert("This pincode is incorrect ");
          return;
        }
        this.showMsg= true;
        alert("Registered Successfully!!!!")
        setTimeout(() => {
          this.router.navigate(['/agents']);
        }, 1000);
       // this.router.navigate(['/agents']);
      });
    }
    else if(this.user_type==1 && this.emailrequired==false && this.mobilerequired==false)
    {

      let param =
    {
      
      name:this.addagentFormGroup.value['name'],
     gender: this.addagentFormGroup.value['gender'],
      dob: this.addagentFormGroup.value['dob'],
      email: this.addagentFormGroup.value['email'],
      primary_mobile: this.addagentFormGroup.value['primary_mobile'],
      whatsup_number: this.addagentFormGroup.value['whatsup_number'],
      landline_number: this.addagentFormGroup.value['landline_number'],
      currently_employed: this.addagentFormGroup.value['currently_employed'],
      job_title : this.addagentFormGroup.value['job_title'],
      company_name : this.addagentFormGroup.value['company_name'],
      company_address : this.addagentFormGroup.value['company_address'],
      housename : this.addagentFormGroup.value['housename'],
      street : this.addagentFormGroup.value['street'],
      country_id : this.addagentFormGroup.value['country_id'],
      state_id : this.addagentFormGroup.value['state_id'],
      district : this.addagentFormGroup.value['district'],
      pincode : this.addagentFormGroup.value['pincode'],
     
      package_id:this.addagentFormGroup.value['package_id'],
      isabroad:this.isAbroad,
      soft_copy:this.soft_copy,
      payment_done:this.payment_done
    } 

    console.log("enteredddddddddddd",param);
      return this.httpServic.postServiceOfficialWithoutToken('user/registersubscriber', param).subscribe(data=>{
        console.log(data);
        if(data.status==0 && data.data==1)
        {
          alert("This Email already exist");
          return;
        }
        if(data.status==0 && data.data==0)
        {
          alert("This Phone no: already exist");
          return;
        }
        if(data.status==0 && data.data==2)
        {
          alert("This pincode is incorrect ");
          return;
        }
        //sessionStorage.setItem('sub_ID', data.sub_ID);
        this.sub_id=data.sub_ID
       // console.log(this.sub_id)
        this.showMsg= true;
        this.router.navigate(['agents']);
      });

    }
    
    
  }

  

  onCancelClick()
 {

   this.router.navigateByUrl('agents');
 }
 
}
