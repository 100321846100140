import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboardSub.component.html',
  styleUrls: ['../app.component.scss','./dashboardSub.component.scss']
 
})
export class DashboardSubscriberComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public userDetils: any;
  public dashboarddata: any;
  public userdata: any;
  public newsArray:any;
  public pack_expire:any;
  public gen:any;
  public subsId:any;


;


  loaded: boolean = false;




  public inactiveusers: any;
  public amountreceived: any;
  public totalagent: any;
  public abroadSubscribedCount: any;
  public abroadSubscribedYear: any;
  public indianSubscribedCount: any;
  public indianSubscribedYear: any;
  public chartReady:any;
  public lineChartIndianData: ChartDataSets[];
  public lineChartAbroadData: ChartDataSets[];
  public lineChartIndianLabels: Label[];
  public lineChartAbroadLabels: Label[];
  lineChartOptions = {
    responsive: true,
  };
 
  lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,0,128,0.28)',
    },
  ];
  lineChartAbroadColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,128,0,0.12)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    private http: HttpClient
    ){
      // this.bindChart();
     }

  ngOnInit() {
  
    this.getdata();
    this.getNews();
    this.pack_expire=sessionStorage.getItem('pack_expire');
    this.gen=sessionStorage.getItem('gender');
    this.subsId=sessionStorage.getItem('subscriber_id');

    



    // this.listsubscribers();
   
    
  }


 
 


  getdata(){

    this.userType=sessionStorage.getItem('user_type');
   this.agent_id=sessionStorage.getItem('subscriber_id');
    
    let param= {
      user_id:this.agent_id
    }
    if(this.userType==2)
    {
    
     return this.httpServic.postServiceOfficial('dashboard/subscriberdashboard', param)
     .subscribe(data=>{
       console.log(data);
        this.dashboarddata=data.pack_details;
        this.userdata=data.profile_details;

        
      //  console.log(this.dashboarddata);
        
         
      });
    }
    // else{ 
    //   return this.httpServic.getServiceOfficial('dashboard/subscriberdashboard', param).subscribe(data=>{
     
    //   this.totalSubscribed=data.count.totalSubscribed;
    //   this.amountreceived=data.count.amountreceived;
    //   this.inactiveusers=data.count.inactiveusers;
    //   this.totalagent=data.count.totalagent;
      
    //  });
    // }
     
  }
  getNews(){

    this.userType=sessionStorage.getItem('user_type');
   this.agent_id=sessionStorage.getItem('subscriber_id');
    
    let param= {
      user_id:this.agent_id
    }
    let localUrl='http://sevasram.org/index.php/wp-json/wp/v2/posts/'
    return this.http.get('https://sevasram.org/wp-json/wp/v2/posts').subscribe(data => {
      console.log(data);
      // this.newsArray = data;
      this.newsArray=data;
      this.loaded= true;
      // console.log(this.newsArray);

     
    });
    
    // return this.httpServic.postServiceOfficialWithoutTokenNews('/')
    //  .subscribe(data=>{
    //    console.log(data);
     
        
         
    //   });
     
        
     
    
   
     
  }
  openpdf(data){
    // console.log(data);
    let url= data.link;
    window.open(url)
  }

  listsubscribers(){

    let param= {
      user_id:this.agent_id
    }
    if(this.userType==1)
    {
      return this.httpServic.postServiceOfficialWithoutToken('subscribers/subscriberlist', param).subscribe(data=>{
       
        this.Listsubscribers = data.subscribers;
       
      });
    }
    else{
    return this.httpServic.getServiceOfficial('dashboard/subscriberlistadmin').subscribe(data=>{
   
      // if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        this.Listsubscribers = data.subscribers;
      
      //}
    });
  }
     
  } 
   
     
  //} 
  renewPackage(){
    this.router.navigate(['/renew']);

  }

}
