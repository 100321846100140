import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { HttpClientService } from 'src/app/services/http-service';
import { DataService } from 'src/app/services/data-service';
import { Subscription } from 'rxjs';
import { ConstantsService } from 'src/app/services/constants.service';
import * as moment from 'moment';


@Component({
  selector: 'app-edit-releasebooks',
  templateUrl: './edit-releasebooks.component.html',
  styleUrls: ['./edit-releasebooks.component.scss']
})
export class EditReleasebooksComponent implements OnInit {

  
  addreleasebooksFormGroup: FormGroup;  
  public status: any;
  public fileName;
  public Bookreleasedetails: any;
  // fileToUpload: File = null;
  public thumpnailToUpload: any;
  public volumeToUpload: any;
  public formData;
  public allBooks: [];
  //public id:any;
  submitted = false;
  fileUploadService: any;
  private subscribeData: Subscription = null;
  public Release_Date: any;
  public book_title: any;
  public thumpnail: any;
  public attachment: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService,
    private userService: UserService, 
    private dataService: DataService) { }


  ngOnInit() {
    this.createaddreleasebooksForm();
    this.formData  = new FormData();
    this.listBooks();
    if(this.dataService.getData()) {
      this.subscribeData = this.dataService.getData().subscribe(data => {
        if(data!=null) {
          this.Bookreleasedetails = data;
         
          this.formFiller();
         
          
        }
      });
    }
  }
  listBooks()
  {
    return this.httpServic.getServiceOfficial('packages/listBooks').subscribe(data=>{
     this.allBooks=data.packages;
    });
  }
  createaddreleasebooksForm() {
    this.addreleasebooksFormGroup = this.formBuilder.group({
      book: ['',Validators.required],
      volume: ['',Validators.required],
      Thumpnail: [''],
      Attachment: [''],
      Release_Date: ['',Validators.required]
      
    
    });
  }
  formFiller() {
    
    this.addreleasebooksFormGroup.patchValue({
      book: this.Bookreleasedetails.title,
      volume : this.Bookreleasedetails.volume,
      Release_Date : moment(this.Bookreleasedetails.release_date).format('YYYY-MM-DD')
     // Thumpnail: this.Bookreleasedetails.thumpnail,
     // Attachment: this.Bookreleasedetails.attachment
    });
    //throw new Error("Method not implemented.");
    this.Release_Date =  new Date(this.Bookreleasedetails.release_date);
    this.book_title=this.Bookreleasedetails.title;
    this.thumpnail=this.Bookreleasedetails.thumpnail;
    this.attachment=this.Bookreleasedetails.attachment;
  }


 
  
  handleThumpnailFileInput(files: FileList) {

    
    this.thumpnailToUpload = files.item(0);
    if(this.thumpnailToUpload.size > 0 && this.thumpnailToUpload.size!=undefined){
     
      this.formData.append("Thumpnail", this.thumpnailToUpload);
    
     
    }

  }
  handleVolumeFileInput(files: FileList) {
   
    this.volumeToUpload = files.item(0);
   
    if(this.volumeToUpload.size > 0 && this.volumeToUpload.size!=undefined){
     
      this.formData.append("Attachment", this.volumeToUpload);
    
    }

  }
showMsg: boolean = false

submitrelasevolume(){
  this.submitted = true;
  if (this.addreleasebooksFormGroup.invalid) {
    return;
}
  let book = (<HTMLSelectElement>document.getElementById('books')).value;
  let volume= (document.getElementById("volume") as HTMLInputElement).value;
  let release_date=(<HTMLSelectElement>document.getElementById('release_date')).value;
 let id=this.Bookreleasedetails.id;
  this. formData.append("book",book);
  this. formData.append("volume",volume);
  this. formData.append("release_date",release_date);
  this.formData.append("id",id)
  this.status = 200;
 
  return this.httpServic.uploadFileService('packages/EditBookrelease', this.formData).subscribe(data=>{
    
    this.showMsg= true;
    this.router.navigateByUrl('books/release-books');
 
});

}
onCancelClick()
{
  this.router.navigateByUrl('/books/release-books');
}
}
