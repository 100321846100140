import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgxPrintModule } from 'ngx-print';
import { DashboardSubscriberComponent } from './dashboardSubscriber/dashboardSub.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsComponent } from './forms/forms.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { TablesComponent } from './tables/tables.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AccordionsComponent } from './accordions/accordions.component';
import { BadgesComponent } from './badges/badges.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TabsComponent } from './tabs/tabs.component';
import { SidebarSuperadminComponent } from './sidebar-superadmin/sidebar-superadmin.component';
import { SidebarAdminComponent } from './sidebar-admin/sidebar-admin.component';
import { SidebarAgentComponent } from './sidebar-agent/sidebar-agent.component';

import { AgentsComponent } from './agents/agents.component';
import { AddAgentComponent } from './agents/add-agent/add-agent.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { AddSubscriberComponent } from './subscriber/add-subscriber/add-subscriber.component';
import { PackagesComponent } from './packages/packages.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { LoginComponent } from './login/login.component';
import { BooksComponent } from './books/books.component';
import { AddBooksComponent } from './books/add-books/add-books.component';
import { ReleaseBooksComponent } from './books/release-books/release-books.component';
import { AddReleaseBooksComponent } from './books/release-books/add-release-books/add-release-books.component';
import { EditAgentComponent } from './agents/edit-agent/edit-agent.component';
import { EditBooksComponent } from './books/edit-books/edit-books.component';
import { EditPackagesComponent } from './packages/edit-packages/edit-packages.component';
import { EditReleasebooksComponent } from './books/release-books/edit-releasebooks/edit-releasebooks.component';
import { UserService } from './services/user-service';
import { DataService } from './services/data-service';
import { HttpClientService } from './services/http-service';
import { HttpClientModule } from '@angular/common/http';
import { ReportsComponent } from './reports/reports.component';
import { InnerComponent } from './layout/inner/inner.component';
import { OuterComponent } from './layout/outer/outer.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
// import { DashboardSuperadminComponent } from './dashboard-superadmin/dashboard-superadmin.component';
// import { DashboardAdminComponent } from './dashboard-admin/dashboard-admin.component';
// import { DashboardAgentComponent } from './dashboard-agent/dashboard-agent.component';
import {DataTablesModule} from 'angular-datatables';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AgentUsermanagementComponent } from './agent-usermanagement/agent-usermanagement.component';
import { AdminUsermanagementComponent } from './admin-usermanagement/admin-usermanagement.component';
import { printwrapperComponent } from './printwrapper/printwrapper.component';
import { collectiondetailsComponent } from './collection-details/collection-details.component';
import { RenewlistComponent } from './renewlist/renewlist.component';
import { RenewagentComponent } from './renewlist/renew/renew.component';
import { RegistrationComponent } from './registration/registration.component';
import { PasswordChangeComponent } from './passwordChange/passwordChange.component';
import { ProfileSubscriberComponent } from './profile/profile.component';

import { SubscribedVolumeComponent } from './subscribedVolumes/subscribedVolumes.component';
import { NotificationComponent } from './notification/notification.component';
import { PaymentHistroryComponent } from './paymentHistory/paymentHistory.component';
import { PayComponent } from './pay/pay.component';
import { RenewComponent } from './renew/renew.component';
import { TempReg } from './tempRegistration/tempReg.component';


import { TempPass } from './tempPass/tempPass.component';
import { TempPay } from './tempPay/tempPay.component';
import { AfterPaymentComponent } from './afterPayment/afterPayment.component';
import { bookreleaseagentComponent } from './bookrelease-agent/bookrelease-agent.component';
import {DatePipe} from '@angular/common';










@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    FormsComponent,
    ButtonsComponent,
    TablesComponent,
    TypographyComponent,
    IconsComponent,
    AlertsComponent,
    AccordionsComponent,
    BadgesComponent,
    ProgressbarComponent,
    BreadcrumbsComponent,
    PaginationComponent,
    DropdownComponent,
    TooltipsComponent,
    CarouselComponent,
    TabsComponent,
    SidebarSuperadminComponent,
    SidebarAdminComponent,
    SidebarAgentComponent,
    AgentsComponent,
    AddAgentComponent,
    SubscriberComponent,
    AddSubscriberComponent,
    PackagesComponent,
    AddPackagesComponent,
    LoginComponent,
    BooksComponent,
    AddBooksComponent,
    ReleaseBooksComponent,
    AddReleaseBooksComponent,
    EditAgentComponent,
    EditBooksComponent,
    EditPackagesComponent,
    EditReleasebooksComponent,
    ReportsComponent,
    InnerComponent,
    OuterComponent,
    ChangePasswordComponent,
    AgentUsermanagementComponent,
    AdminUsermanagementComponent,
    printwrapperComponent,
    collectiondetailsComponent,
    RenewlistComponent,
    RenewagentComponent,
    bookreleaseagentComponent,
   // NgxPrintModule
    // DashboardSuperadminComponent,
    // DashboardAdminComponent,
    // DashboardAgentComponent
    RegistrationComponent,
    DashboardSubscriberComponent,
    PasswordChangeComponent,
    ProfileSubscriberComponent,
    SubscribedVolumeComponent,
    NotificationComponent,
    PaymentHistroryComponent,
    PayComponent,
    RenewComponent,
    TempPass,
    TempPay,
    AfterPaymentComponent,
    TempReg

  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    DataTablesModule,
    ChartsModule ,
    NgxPrintModule
  
  ],
  providers: [UserService, HttpClientService, DataService,ThemeService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }