import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { CustomvalidationService } from '../customvalidation.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  ChangepasswordFormGroup: FormGroup;
  public agent_id: any;
  public password: any;
  showMsg: boolean = false;
  public confirm_password: any;
  submitted = false;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,
    private route: ActivatedRoute,
    private customValidator: CustomvalidationService) { 
    
  }

  ngOnInit() {
   this.createchangepassForm() ;
   this.agent_id = this.route.snapshot.queryParamMap.get('agent_id');
   //this.route.snapshot.queryParamMap.get('firstParamKey');
 
  }

  get registerFormControl() {
    return this.ChangepasswordFormGroup.controls;
  }

  createchangepassForm() {
    this.ChangepasswordFormGroup = this.fromBuilder.group({
      oldpassword:['',Validators.required],
      newpassword:['', Validators.compose([Validators.required, this.customValidator.patternValidator()])],
      confirmpassword:['',Validators.required],
      userid:['']
      
     
    });
  }

  changepasswordClick(){

    this.submitted = true;
   
    this.ChangepasswordFormGroup.controls['userid'].setValue(this.agent_id);
    let param = this.ChangepasswordFormGroup.value;
   this.password=this.ChangepasswordFormGroup['newpassword'];
   this.confirm_password=this.ChangepasswordFormGroup['confirmpassword'];
if(this.password==this.confirm_password)
{
      return this.httpServic.postServiceOfficialWithoutToken('user/changepassword', param).subscribe(data=>{
      
        if(data.status==1){
          this.showMsg= true;
        this.router.navigate(['/login']);

       
        }
        else
        {
          alert("password does not match")
        }
        
      });
      
    
  }
  else{
    alert("password does not match")
  }
}

}
