import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClientService } from 'src/app/services/http-service';
import { ConstantsService } from 'src/app/services/constants.service';
import { UserService } from 'src/app/services/user-service';
import { DataService } from 'src/app/services/data-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.scss']
})
export class EditAgentComponent implements OnInit {

  editagentFormGroup: FormGroup;
  private subscribeData: Subscription = null;
  public agentdetails: any;
  public countryId: any;
  public dob:any;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  showMsg: boolean = false;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService,
    private userService: UserService, 
    private dataService: DataService) { }
    public is_agent = false;
    public checkValue=true;
    ngOnInit() {
      this.createeditagentForm();
      this.listCountries();
     
      if(this.dataService.getData()) {
        this.subscribeData = this.dataService.getData().subscribe(data => {
        
          if(data!=null) {
            this.agentdetails = data;
            
            this.formFiller();
          }
        });
      }
      this.listStates();
    }

    listCountries()
    {
      return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
       if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
          this.countries = dataCountries.data;
        }
      });
    }
    OnChangeCountry(event)
    {
    
      this.countryId=event.target.value;
      this.listStates();
    }
    onChangeUserType(event)
    {
      
      if(event.target.value=="2")
      {
        this.is_agent=true;
      }
      else
      {
        this.is_agent=false;
      }
    }
    listStates(){
      let param = {
        country_id: this.countryId
      }
      return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
       if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
          this.states = dataStates.data;
          
        }
      });
       
    } 
  
    createeditagentForm() {
      this.editagentFormGroup = this.formBuilder.group({
        user_type:[''],
        agent_id:[''],
        name:[''],
        gender: [''],
        dob:[''],
        email:[''],
        primary_mobile:[''],
        whatsup_number:[''],
        landline_number:[''],
        currently_employed:[''],
        job_title:[''],
        company_name:[''],
        company_address:[''],
        housename:[''],
        street:[''],
        country_id:[''],
        state_id:[''],
        district:[''],
        pincode:[''],
        is_active:[]
       
      });
    }
  

    formFiller() {
      this.editagentFormGroup.patchValue({
        
        user_type: this.agentdetails.user_type_id,
        agent_id:this.agentdetails.Agent_id,
        name: this.agentdetails.Name,
        gender: this.agentdetails.gender,
        dob: this.agentdetails.dob,
        email: this.agentdetails.Email,
        primary_mobile: this.agentdetails.Mobile,
        whatsup_number: this.agentdetails.whatsup_number,
        landline_number: this.agentdetails.landline_number,
        currently_employed: this.agentdetails.currently_employed,
        job_title : this.agentdetails.job_title,
        company_name : this.agentdetails.company_name,
        company_address : this.agentdetails.company_address,
        housename : this.agentdetails.housename,
        street : this.agentdetails.street,
        country_id : this.agentdetails.country_id,
        state_id : this.agentdetails.state_id,
        district : this.agentdetails.district,
        pincode : this.agentdetails.pincode,
        is_active:this.agentdetails.is_active
       
      });
      this.countryId=this.agentdetails.country_id;
      this.dob =  new Date(this.agentdetails.dob);
      if(this.agentdetails.user_type_id=="Agent")
      {
        this.is_agent=true;
      }
      else
      {
        this.is_agent=false;
      }
      if(this.agentdetails.is_active==0)
      {
        this.checkValue=false;
      }
      else
      {
        this.checkValue=true;
      }
    }

    submitagent() {
      if(!this.editagentFormGroup.invalid) {
        let param = this.editagentFormGroup.value;
        
        return this.httpServic.patchServiceOfficial('packages/agenteditadmin', param).subscribe(data=>{
      
         this.showMsg= true;
         this.router.navigate(['/agents']);
        });
  
      }
    }
  cancelclick()
  {
    this.router.navigate(['/agents']);
  }

}
