import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { DataService } from 'src/app/services/data-service';
import { HttpClientService } from 'src/app/services/http-service';

@Component({
  selector: 'app-release-books',
  templateUrl: './release-books.component.html',
  styleUrls: ['./release-books.component.scss']
})
export class ReleaseBooksComponent implements OnInit {

  ListBooksrelease :any; 
 public usertype :any;  

  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,){ }

  ngOnInit() {
    this.ListBookrelease();
    this.usertype=sessionStorage.getItem('user_type')
  }

  AddReleaseBooksClick(){
    this.router.navigate(['/books/release-books/add-release-books']);
}



EditReleaseBooksClick(data){

    this.dataService.setData(data);
  this.router.navigate(['/books/release-books/edit-releasebooks']);
}

ListBookrelease(){
  return this.httpServic.getServiceOfficial('packages/listBooksrelease').subscribe(data=>{

    if(data.status==1 && data.packages.length>0 && data.packages != undefined){
      this.ListBooksrelease = data.packages;
     
    }
  });
   
} 

deletebooks(data)
{
 
  
  return this.httpServic.deleteServiceOfficial('packages/bookreleasedelete/'+data.id).subscribe( data=>{
    this.ListBookrelease();
  });

}
}
