import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-payment-history',
  templateUrl: './paymentHistory.component.html',
  styleUrls: ['../app.component.scss','./paymentHistory.component.scss']
 
})
export class PaymentHistroryComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public userDetils: any;
  public dashboarddata: any;
  public userdata: any;
  public name: any;
  public gender: any;
  public onlySoft: any;
  public dob: any;
  public email: any;
  public watsap: any;
  public landline: any;
  public house: any;
  public job: any;
  public street: any;
  public country_id: any;
  public state_id: any;
  public district: any;
  public pincode: any;
  public postoffice: any;
  public mobile: any;
  public date: any;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public countryId: any;

  public paymentDetailsArray: Array<any> = [];
  public gen: any;
  public packName: any;
  public packExp: any;
  public subsName: any;
  public subsId:any;





 
  
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    ){

     }
   

  ngOnInit() {
  
    this.getdata();
    this.gen=sessionStorage.getItem('gender');
    this.subsName=sessionStorage.getItem('name');
    this.packExp=sessionStorage.getItem('packExp');
    this.packName=sessionStorage.getItem('packName');
    this.subsId=sessionStorage.getItem('subscriber_id');

   
    
  }
  


  getdata(){

    this.userType=sessionStorage.getItem('user_type');
   this.agent_id=sessionStorage.getItem('subscriber_id');
    
    let param= {
      user_id:this.agent_id
    }
    // if(this.userType==2)
    
    
     return this.httpServic.postServiceOfficial('payment/paymenthistory', param)
     .subscribe(data=>{
      //  console.log(data);
       this.paymentDetailsArray= data.subscribers;
      //  console.log(this.paymentDetailsArray);

     
      

        
         
      });
    
   
     
  }
  

    

  
  
     
  
   
     
  

}
