import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { forEach } from 'jszip';


@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss']
})
export class AgentsComponent implements OnInit {
  Listagents :any;  
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,){ }


  ngOnInit() {
    this.listagents();
  }

  AddagentClick(){
    this.router.navigate(['/agents/add-agent']);
  }
  onEditagentClick(data:any){
 
   this.dataService.setData(data);    
    this.router.navigate(['/agents/edit-agent']);
    //let param=data.Agent_id;
    // let param = {
    //   Agent_id: data.Agent_id
    // }
  
    
  //  return this.httpServic.postServiceOfficialWithoutToken('packages/editagents',param).subscribe(data=>{
    
    // });
  }

  deleteagents(data)
  {
    console.log(data)
   
  return this.httpServic.deleteServiceOfficial('packages/agentdelete/'+data.id).subscribe( data=>{
    
    this.listagents();
  });
  
  }

  listagents(){
    return this.httpServic.getServiceOfficial('packages/listagents').subscribe(data=>{
      
      if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        console.log(data);
        // data.packages.forEach(item => {
        //   console.log("list", item);
        //   for(var i = 0; i < data.packages.length; i++){
        //     if(item.Agent_id!= null){
        //       console.log("insideItem", item)
        //       this.Listagents = item;
            
        //     }
        //   }
        // });
        this.Listagents= data.packages.filter(s => s.dob!= null)
       
     
      }
    });
     
  } 

}
