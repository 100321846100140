import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router,NavigationEnd } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-tempPay',
  templateUrl: './tempPay.component.html',
  styleUrls: ['./tempPay.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TempPay implements OnInit {
  
  public name: any;
  public customerId: any;
  public transactionId: any;
  public amount: any;
  public osType : any;
  public enc_data: any = {};
  public enc_data1: any = {};
  currentRoute: string;



  public usertype: any;
  submitted = false;
  showMsg: boolean = false;
  LoginFormGroup: FormGroup;

  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService) { 
     
  }
  

  ngOnInit() {
this.name=localStorage.getItem('nameSub');
this.customerId=localStorage.getItem('subscriber_id');

     
             


  }

  
  payChangeClick(){
    let params= {
      user_id:localStorage.getItem('subscriber_id')
    } 
    return this.httpServic.postServiceOfficial('payment/generateTransactionId', params)
                .subscribe(data=>{
                  sessionStorage.setItem('transaction_id',data.transactionId);
                  localStorage.setItem('transaction_id',data.transactionId);


                  this.transactionId= data.transactionId;
                  this.amount= data.Amount;
                  this.osType ="desktop";
                 
                  this.enc_data1= "txnid=" + this.transactionId + "&" + "cust_id=" + this.customerId + "&"+"amount=" + this.amount+ "&"+"os_type="+"desktop"
                 
                  // console.log(this.enc_data1);
                     this.enc_data=btoa(this.enc_data1);
                      let url= "https://payment.sevasram.org/api/gateway/Worldline/PaymentForm?enc_data="
                      let enc_data= this.enc_data;
                     window.open(url+enc_data,"_self");
                    //  window.open(url+enc_data);
                   
                    

                });
  }
}
