import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { HttpClientService } from 'src/app/services/http-service';

@Component({
  selector: 'app-add-release-books',
  templateUrl: './add-release-books.component.html',
  styleUrls: ['./add-release-books.component.scss']
})
export class AddReleaseBooksComponent implements OnInit {

  addreleasebooksFormGroup: FormGroup;  
  public status: any;
  public fileName;
  // fileToUpload: File = null;
  public thumpnailToUpload: any;
  public volumeToUpload: any;
  public formData;
  submitted = false;
  fileUploadService: any;
  public allBooks: [];
  public book_name: any;
  public volume_name: any;
  public release_date: any;
  public title: any;
  showMsg: boolean = false;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,) { 
    
  }

  ngOnInit() {
    this.createaddreleasebooksForm();
    this.formData  = new FormData();
    this.listBooks();
     }

  listBooks()
  {
    return this.httpServic.getServiceOfficial('packages/listBooks').subscribe(data=>{
     this.allBooks=data.packages;
    });
  }
  createaddreleasebooksForm() {
    this.addreleasebooksFormGroup = this.fromBuilder.group({
      book: ['',Validators.required],
      volume: ['',Validators.required],
      Thumpnail: ['',Validators.required],
      Attachment: ['',Validators.required],
      Release_Date: ['',Validators.required]
    
    });
  }

  handleThumpnailFileInput(files: FileList) {

    // Setting filename to upload
    // let bookName = this.addreleasebooksFormGroup.controls['book'].value;
    // let volumeName  = this.addreleasebooksFormGroup.controls['volume'].value;
    // this.fileName = bookName+"-"+volumeName;
    
    this.thumpnailToUpload = files.item(0);
    if(this.thumpnailToUpload.size > 0 && this.thumpnailToUpload.size!=undefined){
     
      this.formData.append("Thumpnail", this.thumpnailToUpload);
    
      //this.addreleasebooksFormGroup.controls['Thumpnail'].setValue(this.thumpnailToUpload);
      
      // return this.httpServic.uploadFileServiceOfficial('', formData).subscribe(data=>{
      ;
      // })
    }

  }
  handleVolumeFileInput(files: FileList) {
   
    // Setting filename to upload
    // let bookName = this.addreleasebooksFormGroup.controls['book'].value;
    // let volumeName  = this.addreleasebooksFormGroup.controls['volume'].value;
    // this.fileName = bookName+"-"+volumeName;
    
    this.volumeToUpload = files.item(0);
    
    if(this.volumeToUpload.size > 0 && this.volumeToUpload.size!=undefined){
     
      this.formData.append("Attachment", this.volumeToUpload);
    
     // this.addreleasebooksFormGroup.controls['Thumpnail'].setValue(this.thumpnailToUpload);
      
      // return this.httpServic.uploadFileServiceOfficial('', formData).subscribe(data=>{
      //   console.log(data);
      // })
    }

  }


submitrelasevolume(){
  this.submitted = true;
  if (this.addreleasebooksFormGroup.invalid) {
    return;
}
  let book = (<HTMLSelectElement>document.getElementById('books')).value;
  let volume= (document.getElementById("volume") as HTMLInputElement).value;
  let release_date=(<HTMLSelectElement>document.getElementById('release_date')).value;
  this. formData.append("book",book);
  this. formData.append("volume",volume);
  this. formData.append("release_date",release_date);
  this.status = 200;
 
  return this.httpServic.uploadFileService('packages/AddBookrelease', this.formData).subscribe(data=>{
    
    this.showMsg= true;
    this.showMsg= true;
    setTimeout(() => {
      this.router.navigate(['books/release-books']);
    }, 2000);


    this.title="You have a new volume Release"
    this.book_name=this.addreleasebooksFormGroup.value['book']
    this.volume_name=this.addreleasebooksFormGroup.value['volume']
    this.release_date=this.addreleasebooksFormGroup.value['Release_Date']
    console.log(this.book_name)
    console.log(this.volume_name)
    let param={
        title:this.title,
         description:this.volume_name+" released on "+this.release_date,
         notification_to:0,
         posted_date:this.release_date
      }

    return this.httpServic.postServiceOfficialWithoutToken('notification/AddBooksreleasenotification', param).subscribe(data=>{
   
      
      
    });
 
});
}
onCancelClick()
{
  this.router.navigateByUrl('/books/release-books');
}
}
