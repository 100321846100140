import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {enableProdMode} from '@angular/core';


@Component({
  
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationComponent implements OnInit {
  
  username: string;
  // amount: string;
  public status: any;
  public amount: any;
  public packageId: any;
  public mesage: any;
  public todayDate: any;


  public namePrev: any;
  public name: any;
  public gender: any;
  public onlySoft: any;
  public dob: any;
  public email: any;
  public watsap: any;
  public landline: any;
  public house: any;
  public job: any;
  public street: any;
  public country_id: any;
  public state_id: any;
  public district: any;
  public pincode: any;
  public post_office: any;
  public mobile: any;
  public currently_employed: any;

  public transactionId: any;
  public pack_name: any;

  // public amount: any;
  public osType : any;
  // public enc_data:any;
  public enc_data: any = {};
  public enc_data1: any = {};
  public customerId: any;


  public messagename: any;
  formvalid: boolean = false;

  public messagegender: any;
  public messageemail: any;
  public messageemail2: any;

  public messagedob: any;

  public messagemobile: any;
  public messagemobile2: any;

  public messagehouse: any;
  public messagestreet: any;
  public messagecountry: any;
  public messagedistrict: any;

  public messagestate: any;
  public messagepostoffice: any;
  public messagepin: any;
  public packageAbroad: any;
  public packageIndian: any;







  public usertype: any;
  submitted = false;
  showMsg: boolean = false;
  proChange: boolean = false;
  readonly: boolean = false;


  payModule: boolean = false;
  cardModule: boolean = false;
  registrationFormGroup: FormGroup;
  public isabroad = false;
  public proceedForm = false;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public countryId: any;
  public company_name: any;
  public company_address: any;




  // isabroad: boolean = false;



  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpService: HttpClientService,
    private constService: ConstantsService
    ) { 
    
  }

  ngOnInit() {
    // console.log(this.isabroad);
    this.proceedForm = false;
    this.isabroad= false;
    this.cardModule= false;
    this.amount = "";
    this.pack_name= null;
    this.listCountries();
    this.listPackages1();
    this.listPackages2();
    this.todayDate =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);


  }
  listCountries()
  {
    return this.httpService.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
      }
    });
  }
  OnChangeCountry(event)
  {
    
    this.country_id=event.target.value;
    this.listStates();
  }
  listStates(){
    let param = {
      country_id: this.country_id
    }
    return this.httpService.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 
  listPackages1()
  {
    let param= {
      "is_abroad":1
    }
 this.httpService.postServiceOfficial('packages/listPackages', param)
     .subscribe(data=>{
      //  console.log(data);
        this.packageAbroad=data.packages;

        
      //  console.log(this.packageAbroad);
        
         
      });
    }


    listPackages2()
      {
        let param= {
          "is_abroad":0
        }
        this.httpService.postServiceOfficial('packages/listPackages', param)
            .subscribe(data=>{
              // console.log(data);
                this.packageIndian=data.packages;

                
              // console.log(this.packageIndian);
                
                
              });
            }
  livingForign(){
    this.isabroad= true;
    console.log(this.isabroad);
  }
  livingIndian(){
    this.isabroad= false;
    this.country_id=101;
    console.log(this.isabroad);

  }
  
  amountToPay1(data){
    this.packageId = data.package_id;
    this.amount=data.package_amount;
    this.pack_name=data.package_name;
    this.country_id=101;
    this.listStates();
    this.proChange= true;
    this.isabroad= false;
    console.log(this.isabroad);





    // console.log(this.packageId);
    // console.log(this.amount);
    // console.log(this.pack_name);


  }
  amountToPay2(data){
    this.packageId = data.package_id;
    this.amount=data.package_amount;
    this.pack_name=data.package_name;
    this.proChange= true;
    this.isabroad= true;
    console.log(this.isabroad);





    // console.log(this.packageId);
    // console.log(this.amount);
    // console.log(this.pack_name);


  }
 
  
  proceedForForm(){
    if(this.packageId == null || this.packageId == undefined || this.packageId == ""){
      alert("please choose a package")
    }else if( this.packageId != null || this.packageId != undefined || this.packageId != ""){
      this.proceedForm = true;
    }
    
  }
  clickBack(){
    this.proceedForm = false;
  }
  backForForm(){
    this.proChange = false;
    this.isabroad= false;



  }
  
  onChangeemployment(event: any){
    event.target.checked == true
    ? (this.currently_employed = 1)
    : (this.currently_employed = 0);
    // let value = event.target.checked == true ? 1 : 0;
    // this.currently_employed =value;


}
ValidateEmail() {
  if (
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.email
    ) 
  ) {
    this.formvalid= true;
    this.messageemail2 = ""

    return true;
  } else {
    this.messageemail2 = "Please provide valid email"
    this.messageemail = ""
    // console.log(this.messageemail2)

    this.formvalid= false;
    

    return false;
  }
}

ValidateMobile() {
  if (
    /[0-9]{10}/.test(
      this.mobile
    ) 
  ) {
    this.formvalid= true;
    this.messagemobile2 = ""

    return true;
  } else {
    this.messagemobile2 = "Please provide valid mobile"
    this.messagemobile = ""

    this.formvalid= false;
    

    return false;
  }
}

submitFormsss(){
    if(this.name == null || this.name == undefined || this.name == "" ){
      this.messagename = "You can't leave this empty"
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagename = ""
    }


    if(this.gender == null || this.gender == undefined || this.gender == "" ){
      this.messagegender = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagegender = ""

    }


    if(this.email == null || this.email == undefined || this.email == "" ){
      this.messageemail = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messageemail = ""

    }



    if(this.mobile == null || this.mobile == undefined || this.mobile == "" ){
      this.messagemobile = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagemobile = ""

    }


    if(this.house == null || this.house == undefined || this.house == ""){
      this.messagehouse = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagehouse = ""

    }



    if(this.street == null || this.street == undefined || this.street == "" ){
      this.messagestreet = "You can't leave this empty"
      
      this.formvalid= false;
      // console.log(this.messagestreet);
      
    }
    else {
      this.formvalid= true;
      this.messagestreet = ""

    }


    if(this.country_id == null || this.country_id== undefined || this.country_id== "" ){
      this.messagecountry = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagecountry = ""

    }


    if(this.dob == null || this.dob == undefined || this.dob == ""){
      this.messagedob = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagedob = ""

    }


    if(this.district == null || this.district== undefined  || this.district== ""){
      this.messagedistrict = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagedistrict = ""

    }


    if(this.state_id == null || this.state_id == undefined || this.state_id == "" ){
      this.messagestate = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagestate = ""

    }


    if(this.post_office == null || this.post_office == undefined || this.post_office == "" ){
      this.messagepostoffice = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagepostoffice = ""

    }

    if(this.pincode == null || this.pincode == undefined || this.pincode == "" ){
      this.messagepin = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagepin = ""

    }
    

    if(this.formvalid== true && this.pincode!= "" && this.pincode != undefined
     &&this.post_office!= "" && this.post_office != undefined && this.name!= ""  && this.name!= undefined  && this.house!= ""  && this.house!= undefined && this.dob!= ""
    && this.dob!= undefined && this.mobile!= "" && this.mobile!= undefined &&  this.street!= "" &&  this.street!= undefined && this.state_id!= "" && this.state_id!= undefined && this.country_id!= ""
    && this.country_id!= undefined && this.district!= ""  && this.district!= undefined
    && this.email!= "" && this.email!= undefined && this.gender!= "" && this.gender!= undefined && this.ValidateEmail()== true && this.ValidateEmail()==true){
      let param= {
        name:this.name,
        gender:this.gender,
        package_id:this.packageId,
        soft_copy:this.onlySoft == true ? 1 : 0,
        // user_type:'2',
        dob:this.dob,
        email:this.email,
        primary_mobile:this.mobile,
        whatsup_number:this.watsap,
        landline_number:this.landline, 
        currently_employed:this.currently_employed == true ? "1": "0",
        job_title:this.currently_employed == true ? this.job: "",
        company_name: this.currently_employed == true ? this.company_name : "",
        company_address:this.currently_employed == true ? this.company_address: "",
        housename:this.house,
        street:this.street,
        country_id:this.country_id,
        state_id:this.state_id,
        district:this.district,
        post_office:this.post_office,
        pincode:this.pincode,
        is_active:true,
        payment_done:0,
        isabroad:this.isabroad == true ? 1 : 0
      }
      console.log(param);
      return this.httpService.postServiceOfficialWithoutToken('user/registersubscriber', param)
        .subscribe(data=>{
      sessionStorage.setItem('subs_id', data.sub_ID);
      localStorage.setItem('subs_id', data.sub_ID);


     
      
      if(data.status==0)
      {
      this.mesage= data.message;
      }
      
      if(data.status==1 ){
      this.mesage= "";
        
      this.router.navigate(['/tempReg']);
        this.customerId=sessionStorage.getItem('subs_id');


      }
      
    });
  }

    }
   
   
  onChangeIsSoft(event: any){
      event.target.checked == true
      ? (this.onlySoft = 1)
      : (this.onlySoft = 0);   
  }
  alphaNumOnly(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {

      return true;
      
    } else {
      event.preventDefault();

      return false;
    }
   
  }

	

  

//  

}
