import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';


@Component({
  
  selector: 'app-registration',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RenewComponent implements OnInit {
  
  username: string;
  // amount: string;
  public status: any;
  public amount: any;
  public amounttemp: any;

  public packageId: any;
  public pack_name: any;
  public pack_id: any;
  public pack_amount: any;
  public pack_name2: any;
  public pack_nametemp: any;

  public pack_amount2: any;
public package_changed: any;
public userId: any;
public packageAbroad: any;
public packageIndian: any;
proChange: boolean = false;



  submitted = false;
  show_pack: boolean = false;
  payModule: boolean = false;
  cardModule: boolean = false;
  registrationFormGroup: FormGroup;
  public isabroad = false;
  public proceedForm = false;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public countryId: any;
  public company_name: any;
  public date: any;
  public customerId: any;
  public transactionId: any;
  public osType: any;
  public enc_data: any;
  public enc_data1: any;








  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpService: HttpClientService,
    private constService: ConstantsService
    ) { 
    
  }

  ngOnInit() {
    // console.log(this.isabroad);
    this.pack_name2= null;
    this.pack_nametemp= null;

    this.show_pack = false;
    this.isabroad= false;
    this.cardModule= false;
    this.amount = "";
   this.pack_name=sessionStorage.getItem('pack_name');
   this.pack_id=sessionStorage.getItem('pack_id');
   this.pack_amount=sessionStorage.getItem('pack_amount');
   this.userId=sessionStorage.getItem('subscriber_id');
   this.pack_nametemp= null;
   

   this.convert();
   this.listPackages1();
    this.listPackages2();





  }
  proceedForForm(){
    if(this.packageId == null || this.packageId == undefined || this.packageId == ""){
      alert("please choose a package")
    }else if( this.packageId != null || this.packageId != undefined || this.packageId != ""){
      // this.proceedForm = true;
      this.customerId=sessionStorage.getItem('subscriber_id');

      let params= {
        userid:parseInt(this.customerId),
        package_id:this.packageId,
        package_amount:this.amount

      } 
      return this.httpService.postServiceOfficial('payment/renewpackage', params)
      .subscribe(data=>{
        // console.log(data);
        if(data.status== 1){
          let param2= {
            user_id:localStorage.getItem('subscriber_id')
          }
          return this.httpService.postServiceOfficial('payment/generateTransactionId', param2)
              .subscribe(data=>{
                console.log(data);
                localStorage.setItem('transaction_id', data.transactionId);
                sessionStorage.setItem('transaction_id', data.transactionId);
                this.transactionId= data.transactionId;
                this.amount= this.amount;
                this.osType ="desktop";
                this.enc_data1= "txnid=" + this.transactionId + "&" + "cust_id=" + this.customerId + "&"+"amount=" + this.amount+ "&"+"os_type="+"desktop"
              
                console.log(this.enc_data1);
                  this.enc_data=btoa(this.enc_data1);
                    let url= "https://payment.sevasram.org/api/gateway/Worldline/PaymentForm?enc_data="
                    let enc_data= this.enc_data;
                  window.open(url+enc_data,"_self");

              });

        }
        else{
          alert("something went wrong , please try again")
        }
        

      });
      
    }
  }
  convert() {
      var date = new Date(),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      this.date = [date.getFullYear(), mnth, day].join("-");
      // console.log(this.date);

      return this.date;
    }
  listPackages1()
  {
    let param= {
      "is_abroad":1
    }
      this.httpService.postServiceOfficial('packages/listPackages', param)
     .subscribe(data=>{
      //  console.log(data);
        this.packageAbroad=data.packages;

        
      //  console.log(this.packageAbroad);
        
         
      });
    }


    listPackages2()
      {
        let param= {
          "is_abroad":0
        }
        this.httpService.postServiceOfficial('packages/listPackages', param)
            .subscribe(data=>{
              // console.log(data);
                this.packageIndian=data.packages;

                
              // console.log(this.packageIndian);
                
                
              });
            }

            amountToPay1(data){
              this.packageId = data.package_id;
              this.amount=data.package_amount;
              this.pack_nametemp=data.package_name;
              this.amounttemp=data.package_amount;
              this.proChange= true;
              this.isabroad= false;


    
         
          
          
              // console.log(this.packageId);
              // console.log(this.amount);
              // console.log(this.pack_name);
          
          
            }
            amountToPay2(data){
              this.packageId = data.package_id;
              this.amount=data.package_amount;
              this.pack_nametemp=data.package_name;
              this.amounttemp=data.package_amount;
              this.proChange= true;
              this.isabroad= true;


          
          
              // console.log(this.packageId);
              // console.log(this.amount);
              // console.log(this.pack_name);
          
          
            }
            backForForm(){
              this.proChange = false;
    this.isabroad= false;

          
          
            }

  continueSame(){
    
          this.customerId=sessionStorage.getItem('subscriber_id');

          let params= {
            user_id:this.customerId
          } 
          return this.httpService.postServiceOfficial('payment/generateTransactionId', params)
          .subscribe(data=>{
            // console.log(data);
            localStorage.setItem('transaction_id', data.transactionId);
            sessionStorage.setItem('transaction_id', data.transactionId);


            this.transactionId= data.transactionId;
            this.amount= data.Amount;
            this.osType ="desktop";

          
            this.enc_data1= "txnid=" + this.transactionId + "&" + "cust_id=" + this.customerId + "&"+"amount=" + this.amount+ "&"+"os_type="+"desktop"
          
            console.log(this.enc_data1);
              this.enc_data=btoa(this.enc_data1);
                let url= "https://payment.sevasram.org/api/gateway/Worldline/PaymentForm?enc_data="
                let enc_data= this.enc_data;
              window.open(url+enc_data,"_self");

            });
            }
            
  

 changePackage(){
   this.show_pack= true;
   this.package_changed="1";
 }
  
  livingForign(){
    this.isabroad= true;
    // console.log(this.isabroad);
  }
  livingIndian(){
    this.isabroad= false;
  }
  

  
  submitForm(){ 
       




     
  }


  clickBack(){
    this.show_pack = false;
  }
  

  


 


   
    


       
     
      
    
   
   
  

//  

}
