import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user-service';
import { HttpClientService } from 'src/app/services/http-service';

@Component({
  selector: 'app-add-books',
  templateUrl: './add-books.component.html',
  styleUrls: ['./add-books.component.scss']
})
export class AddBooksComponent implements OnInit {


  addBooksFormGroup: FormGroup;
  submitted = false;
  public status: any;
  constructor(private router: Router, 
    private userService: UserService, 
    private httpServic: HttpClientService,
    private fromBuilder: FormBuilder,) { 
    
  }
  
  ngOnInit() {
    
    this.createaddBooksForm();
  }


  createaddBooksForm() {
    this.addBooksFormGroup = this.fromBuilder.group({
      title: ['',Validators.required],
      description: ['',Validators.required],
      published_date:['',Validators.required],
      
    });
  }
  showMsg: boolean = false;
  submitbooks(){
    this.status = 200;
    this.submitted = true;
    let param = this.addBooksFormGroup.value;
 

    
   if (this.addBooksFormGroup.invalid) {
    return;
}
    
    return this.httpServic.postServiceOfficialWithoutToken('packages/Addbooks', param).subscribe(data=>{
   
      this.showMsg= true;
      setTimeout(() => {
        this.router.navigate(['/books']);
      }, 2000);
      
    });
  }
     
  onCancelClick()
  {
    this.router.navigateByUrl('books');
  }
}
