import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardSubscriberComponent } from './dashboardSubscriber/dashboardSub.component';

import { ProfileSubscriberComponent } from './profile/profile.component';
import { PasswordChangeComponent } from './passwordChange/passwordChange.component';
import { SubscribedVolumeComponent } from './subscribedVolumes/subscribedVolumes.component';
import { NotificationComponent } from './notification/notification.component';
import { PaymentHistroryComponent } from './paymentHistory/paymentHistory.component';
import { PayComponent } from './pay/pay.component';
import { RenewComponent } from './renew/renew.component';
import { TempPass } from './tempPass/tempPass.component';
import { TempPay } from './tempPay/tempPay.component';
import { TempReg } from './tempRegistration/tempReg.component';

import { AfterPaymentComponent } from './afterPayment/afterPayment.component';







import { RegistrationComponent } from './registration/registration.component';
import { FormsComponent } from './forms/forms.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { TablesComponent } from './tables/tables.component';
import { IconsComponent } from './icons/icons.component';
import { TypographyComponent } from './typography/typography.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AccordionsComponent } from './accordions/accordions.component';
import { BadgesComponent } from './badges/badges.component';
import { ProgressbarComponent } from './progressbar/progressbar.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { TooltipsComponent } from './tooltips/tooltips.component';
import { CarouselComponent } from './carousel/carousel.component';
import { TabsComponent } from './tabs/tabs.component';
import { AgentsComponent } from './agents/agents.component';
import { AddAgentComponent } from './agents/add-agent/add-agent.component';
import { EditAgentComponent } from './agents/edit-agent/edit-agent.component';
import { SubscriberComponent } from './subscriber/subscriber.component';
import { AddSubscriberComponent } from './subscriber/add-subscriber/add-subscriber.component';
import { PackagesComponent } from './packages/packages.component';
import { AddPackagesComponent } from './packages/add-packages/add-packages.component';
import { EditPackagesComponent } from './packages/edit-packages/edit-packages.component';
import { LoginComponent } from './login/login.component';
import { BooksComponent } from './books/books.component';
import { AddBooksComponent } from './books/add-books/add-books.component';
import { EditBooksComponent } from './books/edit-books/edit-books.component';
import { ReleaseBooksComponent } from './books/release-books/release-books.component';
import { AddReleaseBooksComponent } from './books/release-books/add-release-books/add-release-books.component';
import { EditReleasebooksComponent } from './books/release-books/edit-releasebooks/edit-releasebooks.component';
import { InnerComponent } from './layout/inner/inner.component';
import { OuterComponent } from './layout/outer/outer.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ReportsComponent } from './reports/reports.component';
import { AgentUsermanagementComponent } from './agent-usermanagement/agent-usermanagement.component';
import { AdminUsermanagementComponent } from './admin-usermanagement/admin-usermanagement.component';
import { printwrapperComponent } from './printwrapper/printwrapper.component';
import { collectiondetailsComponent } from './collection-details/collection-details.component';
import { RenewlistComponent } from './renewlist/renewlist.component';
import { RenewagentComponent } from './renewlist/renew/renew.component';
import { bookreleaseagentComponent } from './bookrelease-agent/bookrelease-agent.component';
const routes: Routes = [
// { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
{ path: '', redirectTo: '/login', pathMatch: 'full' },
{
  path: '', 
  component: OuterComponent,
  children: [
    { path: '', component: LoginComponent, pathMatch: 'full'},
    { path: 'login', component: LoginComponent, pathMatch: 'full'},
    
    { path: 'dashboardSubscriber', component: DashboardSubscriberComponent },
    { path: 'profile', component: ProfileSubscriberComponent },

    
    { path: 'logout', component:LoginComponent , pathMatch: 'full'},
    { path: 'registration', component: RegistrationComponent, pathMatch: 'full' },
    { path: 'payment-history', component: PaymentHistroryComponent, pathMatch: 'full' },
    { path: 'payAmount', component: PayComponent, pathMatch: 'full' },

    { path: 'passwordChange', component: PasswordChangeComponent, pathMatch: 'full' },
    { path: 'notification', component: NotificationComponent, pathMatch: 'full' },
    { path: 'subscribed-volume', component: SubscribedVolumeComponent, pathMatch: 'full' },
    { path: 'renew', component: RenewComponent, pathMatch: 'full' },
    { path: 'tempPass', component: TempPass, pathMatch: 'full' },
    { path: 'tempPay', component: TempPay, pathMatch: 'full' },
    { path: 'tempReg', component: TempReg, pathMatch: 'full' },

    { path: 'result', component: AfterPaymentComponent, pathMatch: 'full' },


  ]
},
{
  path: '',
  component: InnerComponent, 
  children: [
    { path: 'dashboard', component: DashboardComponent },
    // { path: 'registration', component: RegistrationComponent },
    { path: 'forms', component: FormsComponent },
    { path: 'buttons', component: ButtonsComponent },
    { path: 'tables', component: TablesComponent },
    { path: 'icons', component: IconsComponent },
    { path: 'typography', component: TypographyComponent },
    { path: 'alerts', component: AlertsComponent },
    { path: 'accordions', component: AccordionsComponent },
    { path: 'badges', component: BadgesComponent },
    { path: 'progressbar', component: ProgressbarComponent },
    { path: 'breadcrumbs', component: BreadcrumbsComponent },
    { path: 'pagination', component: PaginationComponent },
    { path: 'dropdowns', component: DropdownComponent },
    { path: 'tooltips', component: TooltipsComponent },
    { path: 'carousel', component: CarouselComponent },
    { path: 'tabs', component: TabsComponent },
    { path: 'agents', component: AgentsComponent },
    { path: 'agents/add-agent', component: AddAgentComponent },
    { path: 'agents/edit-agent', component: EditAgentComponent },
    { path: 'subscriber', component: SubscriberComponent },
    { path: 'subscriber/add-subscriber', component:AddSubscriberComponent },
    { path: 'packages', component: PackagesComponent },
    { path: 'packages/add-packages', component: AddPackagesComponent },
    { path: 'packages/edit-packages', component: EditPackagesComponent },
    { path: 'books', component: BooksComponent },
    { path: 'books/add-books', component: AddBooksComponent },
    { path: 'books/edit-books', component: EditBooksComponent },
    { path: 'books/release-books', component: ReleaseBooksComponent },
    { path: 'books/release-books/add-release-books', component: AddReleaseBooksComponent },
    { path: 'books/release-books/edit-releasebooks', component:EditReleasebooksComponent },
    { path: 'change-password', component:ChangePasswordComponent },
    { path: 'reports', component:ReportsComponent },
    { path: 'agent-usermanagement', component:AgentUsermanagementComponent },
    { path: 'admin-usermanagement', component:AdminUsermanagementComponent },
    { path: 'printwrapper', component:printwrapperComponent },
    { path: 'collection-details', component:collectiondetailsComponent },
    { path: 'renewlist', component: RenewlistComponent },
    { path: 'renewlist/renew', component: RenewagentComponent },
    { path:'bookrelease-agent',component:bookreleaseagentComponent},
  ]
},
  //{ path: 'login', component: LoginComponent },
  
  ];

  @NgModule({
    imports: [RouterModule.forRoot(routes,{ useHash: true })],
    exports: [RouterModule]
  })
export class AppRoutingModule { }
