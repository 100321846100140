import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { Router,NavigationEnd } from '@angular/router';
import { UserService } from '../services/user-service';
import { HttpClientService } from '../services/http-service';
import { ConstantsService } from '../services/constants.service';
import { FormGroup, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {
  
  username: string;
  password: string;
  public status: any;
  public customerId: any;
  public transactionId: any;
  public amount: any;
  public osType : any;
  // public enc_data:any;
  public enc_data: any = {};
  public enc_data1: any = {};
  currentRoute: string;



  public usertype: any;
  submitted = false;
  showMsg: boolean = false;
  LoginFormGroup: FormGroup;

  constructor(private router: Router, 
    private userService: UserService, 
    private fromBuilder: FormBuilder,
    private httpServic: HttpClientService,
    private constService: ConstantsService) { 
      // router.events.filter(event => event instanceof NavigationEnd)
      // .subscribe(event => 
      //  {
      //     this.currentRoute = event.url;          
      //     console.log(this.currentRoute);
      //  });
      // setTimeout(() => {
        // router.events.filter(event => event instanceof NavigationEnd)
        // .subscribe(event => 
        //  {
        //     this.currentRoute = event.url;          
        //     console.log(this.currentRoute);
        //  });
      
      // }, 2000);
  }
  

  ngOnInit() {
this. createloginForm();
  }

  createloginForm() {
    this.LoginFormGroup = this.fromBuilder.group({
      username:[''],
      password:['']
     
      
     
    });
  }
  loginClick(){
    this.submitted = true;
      this.username=this.LoginFormGroup.value['username'],
      this.password=this.LoginFormGroup.value['password']
  

    //if(this.username == "Superadmin" && this.password == "admin"){
      
      //this.router.navigate(['/dashboard']);
     
    //}else{
      
      let param = this.LoginFormGroup.value;
      // sessionStorage.setItem('pc', this.password);
   
      return this.httpServic.postServiceOfficialWithoutToken('user/login', param).subscribe(data=>{
        // console.log(data);
        
        sessionStorage.setItem('user_type', data.user_type);
        this.usertype=data.user_type;
        if(this.usertype==1)
          {
           sessionStorage.setItem('agent_id', data.userprofile.userID);
           if(data.status==1)
            {
              this.showMsg= true;
          
                setTimeout(() => {
                  this.router.navigate(['/dashboard']);
                }, 1000);
           }
           else {
            alert("invalid User")
         
            this.router.navigate(['/login']);
                 }
            
          } 
        else 
        if(this.usertype==3)
       {
            if(data.status==1)
            {
              this.showMsg= true;
          
                setTimeout(() => {
                  this.router.navigate(['/dashboard']);
                }, 1000);
           }
           else {
            alert("invalid User")
         
            this.router.navigate(['/login']);
                 }
            
          
          }

       else
       if(this.usertype==2)
          {
            sessionStorage.setItem('subscriber_id', data.userprofile.userID);
            sessionStorage.setItem('gender', data.userprofile.gender);
            sessionStorage.setItem('token', data.userprofile.usertoken);
            sessionStorage.setItem('pass_changestatus', data.pass_changestatus);
            sessionStorage.setItem('pc', this.password);
            sessionStorage.setItem('name', data.userprofile.name);
            sessionStorage.setItem('nameSub', data.userprofile.name);

            
            


            localStorage.setItem('subscriber_id', data.userprofile.userID);
            localStorage.setItem('token', data.userprofile.usertoken);
            localStorage.setItem('pass_changestatus', data.pass_changestatus);
            localStorage.setItem('pc', this.password);
            localStorage.setItem('nameSub', data.userprofile.name);
            localStorage.setItem('gender', data.userprofile.gender);
            


          




           if(data.status==1 && data.pass_changestatus == 1 && data.pack_details.paid_status == 1
            //  && data.pack_details.packexpiry_status == 0
             )
           {
            sessionStorage.setItem('pack_expire', data.pack_details.packexpiry_status);
            sessionStorage.setItem('pack_name', data.pack_details.pack_name);
            sessionStorage.setItem('packExp', data.pack_details.pack_expireson);
            sessionStorage.setItem('packName', data.pack_details.pack_name);
            if(data.pack_details.packexpiry_status == 1){
              sessionStorage.setItem('pack_amount', data.pack_details.pack_amount);
              sessionStorage.setItem('pack_id', data.pack_details.pack_id);
              localStorage.setItem('pack_id', data.pack_details.pack_id);
              localStorage.setItem('pack_name', data.pack_details.pack_name);
            }

            localStorage.setItem('pack_expire', data.pack_details.packexpiry_status);
            localStorage.setItem('pack_name', data.pack_details.pack_name);
            localStorage.setItem('packExp', data.pack_details.pack_expireson);
            localStorage.setItem('packName', data.pack_details.pack_name);



             this.showMsg= true;
              setTimeout(() => {
                 this.router.navigate(['/dashboardSubscriber']);
               }, 2000);
            }

            else if(data.status==1 && data.pass_changestatus == 0)
            {
              this.showMsg= true;
       
             setTimeout(() => {
               this.router.navigate(['/tempPass']);
             }, 1000);
            }

            else if(data.status==1 && data.pass_changestatus == 1 && data.pack_details.paid_status != 1)
            {
              sessionStorage.setItem('pack_amount', data.pack_details.pack_amount);
              sessionStorage.setItem('pack_id', data.pack_details.pack_id);
              sessionStorage.setItem('pack_name', data.pack_details.pack_name);
              sessionStorage.setItem('packExp', data.pack_details.pack_expireson);
            sessionStorage.setItem('packName', data.pack_details.pack_name);


              localStorage.setItem('pack_amount', data.pack_details.pack_amount);
              localStorage.setItem('pack_id', data.pack_details.pack_id);
              localStorage.setItem('pack_name', data.pack_details.pack_name);
              localStorage.setItem('packExp', data.pack_details.pack_expireson);
            localStorage.setItem('packName', data.pack_details.pack_name);

              
              this.customerId=data.userprofile.userID;

              this.showMsg= true;
              this.router.navigate(['/tempPay']);

             
                   
                  
           
            }
            // else if(data.status==1 && data.pass_changestatus == 1 && data.pack_details.paid_status == 1 && data.pack_details.packexpiry_status == 1)
            // {
            //   sessionStorage.setItem('pack_amount', data.pack_details.pack_amount);
            //   sessionStorage.setItem('pack_id', data.pack_details.pack_id);
            //   sessionStorage.setItem('pack_name', data.pack_details.pack_name);
            //   sessionStorage.setItem('pack_expire', data.pack_details.packexpiry_status);


            //   localStorage.setItem('pack_amount', data.pack_details.pack_amount);
            //   localStorage.setItem('pack_id', data.pack_details.pack_id);
            //   localStorage.setItem('pack_name', data.pack_details.pack_name);
            //   localStorage.setItem('pack_expire', data.pack_details.packexpiry_status);

                   
            //   setTimeout(() => {
            //     this.router.navigate(['/dashboardSubscriber']);
            //     // this.router.navigate(['/renew']);

            //   }, 2000);
            // }

            
            }
            else
             {
              alert("Invalid username or password")
              this.router.navigate(['/login']);
               }
               
         
          }
      );
    
   // }

 }
//  arrayBufferToBase64(obj) {
//   let type = typeof obj;
//     if (type === 'object') {
//         for (let key in obj) {
//              obj[key] = this.arrayBufferToBase64(obj[key]);
//         }
//     } else if (type === 'string') {
//         obj = btoa(obj);
//     }
//     return obj;
// }
arrayBufferToBase64(obj) {
  let type = typeof obj;
    if (type === 'object') {
        // for (let key in obj) {
            //  obj[key] = this.arrayBufferToBase64(obj[key]);
        obj = btoa(obj);

        // }
    } else if (type === 'string') {
        obj = btoa(obj);
    }
    return obj;
}
 goToRegistration(){
  this.router.navigate(['/registration']);
 }

}
