import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstantsService } from '../services/constants.service';
@Component({
  selector: 'app-sidebar-superadmin',
  templateUrl: './sidebar-superadmin.component.html',
  styleUrls: ['./sidebar-superadmin.component.scss']
})
export class SidebarSuperadminComponent implements OnInit {
isLoggedIn;
  constructor(private constService: ConstantsService) { 

  }

  ngOnInit() {
    
    this.isLoggedIn=this.constService.isLoggedIn;

  }
  

}
