import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { Agents } from '../agents/agents';


@Component({
  selector: 'app-agents',
  templateUrl: './printwrapper.component.html',
  styleUrls: ['./printwrapper.component.scss']
})
export class printwrapperComponent implements OnInit {
  Listagents :any;  
  ListAgents:any;
  printResults: any[];
  
 
  masterSelected:boolean;
  tempArr: any = { "user_id": [] };

  Count = [];
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,){ 
      this.masterSelected = false;
     // this.getCheckedItemList() ;
      
    }


  ngOnInit() {
    this.listagents();
    
  }

  
  

 

  listagents(){
    return this.httpServic.getServiceOfficial('packages/listsubscribers').subscribe(data=>{
      console.log(data.packages);
      if(data.status==1 && data.packages.length>0 && data.packages != undefined){
        this.Listagents = data.packages;
       
      
     
      }
    });
     
  } 

 
  // onChangeCheckBox(event, agents: any){
  //   console.log(event.target.checked);
  //   if(event.target.checked == true){
  //     this.tempArr.user_id.push(agents.subscriber_id);
  //    console.log(this.tempArr)
  //    this.printResults = [];
  // let param = this.tempArr;
  // console.log(param)
  // return this.httpServic.postServiceOfficialWithoutToken('subscribers/subscriberlocation', param).subscribe(data=>{
  // //if(data.status==1 && data.subscribers.length>0 && data.subscribers != undefined){
  //     this.printResults = data.location;
  //     this.Count.length = 10;
  //   //}
  //   console.log(this.printResults);
   
  // });
  //   }else{
     
     
  //   }
  //   //this.listpackagesAbroad();
  // }

  
  checkUncheckAll(value) {
    if(value)
    {
    
    for (var i = 0; i < this.Listagents.length; i++) {
      this.Listagents[i].isSelected = this.masterSelected;
    }
  }
  else{
   

    for (var i = 0; i < this.Listagents.length; i++) {
      this.Listagents[i].isSelected =false
    }
  }
    this.getCheckedItemList() ;
  }
  
  isAllSelected(value) {
if(value)
{
  
  this.masterSelected = this.Listagents.every(function(agents:any) {
      
    return agents.isSelected == true;

    
  })

}
else{
 
  this.masterSelected = this.Listagents.every(function(agents:any) {
      
    return agents.isSelected == false;

    
  })
}
    
    
   
   this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.tempArr = { "user_id": [] };
    
    for (var i = 0; i < this.Listagents.length; i++) {
      if(this.Listagents[i].isSelected)
      {
      this.tempArr.user_id.push(this.Listagents[i]["subscriber_id"]);
      console.log(this.Listagents[i]["subscriber_id"])
    //this.ListAgents = JSON.stringify(this.tempArr);
    
      }
      else{
       // this.tempArr = { "user_id": [] };




      
       
      }
    
    
  }
  this.printResults = [];
    let param = this.tempArr;
    console.log(param)
    return this.httpServic.postServiceOfficialWithoutToken('subscribers/subscriberlocation', param).subscribe(data=>{
      //if(data.status==1 && data.subscribers.length>0 && data.subscribers != undefined){
          this.printResults = data.location;
         
        //}
        console.log(this.printResults);
       
      });
 
  }

}
