import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user-service';
import { DataService } from '../services/data-service';
import { HttpClientService } from '../services/http-service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['../app.component.scss','./profile.component.scss']
 
})
export class ProfileSubscriberComponent implements OnInit {
  Listsubscribers :any;  
  public userType: any;
  public agent_id: any;
  public userDetils: any;
  public dashboarddata: any;
  public userdata: any;
  public name: any;
  public gender: any;
  public onlySoft: any;
  public dob: any;
  public email: any;
  public watsap: any;
  public landline: any;
  public house: any;
  public job: any;
  public company_name: any;
  public company_address: any;
  public post_office: any;
  public mesage: any;
 


  public street: any;
  public country_id: any;
  public state_id: any;
  public district: any;
  public pincode: any;
  public currently_employed: any;
  public mobile: any;
  public date: any;
  public countries: Array<any> = [];
  public states: Array<any> = [];
  public countryId: any;

  public userDetailsArray: Array<any> = [];


  public messagename: any;
  formvalid: boolean = false;

  public messagegender: any;
  public messageemail: any;
  public messageemail2: any;

  public messagedob: any;

  public messagemobile: any;
  public messagemobile2: any;

  public messagehouse: any;
  public messagestreet: any;
  public messagecountry: any;
  public messagedistrict: any;

  public messagestate: any;
  public messagepostoffice: any;
  public messagepin: any;
  public todayDate: any;
  public gen: any;
  public packName: any;
  public packExp: any;
  public subsName: any;
  public subsId:any;






 
  
  constructor(private router: Router, 
    private userService: UserService, 
    private dataService: DataService,
    private httpServic: HttpClientService,
    ){
     
    // this.convert();

     }
    //  convert() {
    //   var date = new Date(),
    //     mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    //     day = ("0" + date.getDate()).slice(-2);
    //   this.dob = [date.getFullYear(), mnth, day].join("-");
    //   return this.dob;
    // }

  ngOnInit() {
  
    this.getdata();
    this.listCountries();
    
    this.gen=sessionStorage.getItem('gender');
    this.subsName=sessionStorage.getItem('name');
    this.packExp=sessionStorage.getItem('packExp');
    this.packName=sessionStorage.getItem('packName');
    this.subsId=sessionStorage.getItem('subscriber_id');
    this.todayDate =
    new Date().getFullYear() +
    "-" +
    ("0" + (new Date().getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + new Date().getDate()).slice(-2);
 
   
    
  }
  listCountries()
  {
    return this.httpServic.getServiceOfficial('masters/listCountries').subscribe(dataCountries=>{
     if(dataCountries.status==1 && dataCountries.data.length>0 && dataCountries.data != undefined){
        this.countries = dataCountries.data;
        // console.log(this.countries);
      }
    });
  }
  OnChangeCountry(event)
  {
    
    this.countryId=event.target.value;
    this.listStates();
  }
  listStates(){
    let param = {
      country_id: this.countryId
    }
    return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
     if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
        this.states = dataStates.data;
        
      }
    });
     
  } 


  getdata(){

    this.userType=sessionStorage.getItem('user_type');
   this.agent_id=sessionStorage.getItem('token');
    
    let param= {
      token:this.agent_id
    }
    // if(this.userType==2)
    
    
     return this.httpServic.postServiceOfficial('subscribers/subscriberprofile', param)
     .subscribe(data=>{
       console.log("profile data",data);
      let location = data.subscribers.location;
       let userDetailsArray = data.subscribers;
       let jobDetails = data.subscribers.job_details;
       this.name= userDetailsArray.name;
       this.gender=userDetailsArray.gender;
       var date = userDetailsArray.Dob,
       yr = date.slice(-4),
       day = date.slice(0,2),
       mnth = date.slice(3,5);
      this.dob = [yr, mnth, day].join("-");
       this.email= userDetailsArray.Email;
       this.watsap= userDetailsArray.Whatsup_number;
       this.landline= userDetailsArray.landline_number;
       this.house= location.housename;
       this.job= jobDetails.job_title;
       this.company_name= jobDetails.company_name;
       this.company_address= jobDetails.company_address;


       this.street= location.street;
       this.country_id= location.country_id;
       this.state_id= location.state_id;
       this.district= location.district;
       this.pincode= location.pincode;
       this.currently_employed=userDetailsArray.currently_employed;
       this.post_office=location.post_office;
       this.mobile= userDetailsArray.Mobile;
      //  this.onlySoft= this.userDetailsArray.Email;
   
        let param = {
          country_id: this.country_id
        }
        return this.httpServic.postServiceOfficialWithoutToken('masters/listStates', param).subscribe(dataStates=>{
         if(dataStates.status==1 && dataStates.data.length>0 && dataStates.data != undefined){
            this.states = dataStates.data;
            
          }
        });
         
      });
  
     
  }
  onChangeemployment(event: any){
    event.target.checked == true
    ? (this.currently_employed = 1)
    : (this.currently_employed = 0);
    // let value = event.target.checked == true ? 1 : 0;
    // this.currently_employed =value;


}
alphaNumOnly(event) {
  var inp = String.fromCharCode(event.keyCode);

  if (/[a-zA-Z0-9]/.test(inp)) {

    return true;
    
  } else {
    event.preventDefault();

    return false;
  }
 
}
ValidateEmail() {
  if (
    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      this.email
    ) 
  ) {
    this.formvalid= true;
    this.messageemail2 = ""

    return true;
  } else {
    this.messageemail2 = "Please provide valid email"
    this.messageemail = ""
    // console.log(this.messageemail2)

    this.formvalid= false;
    

    return false;
  }
}

ValidateMobile() {
  if (
    /[0-9]{10}/.test(
      this.mobile
    ) 
  ) {
    this.formvalid= true;
    this.messagemobile2 = ""

    return true;
  } else {
    this.messagemobile2 = "Please provide valid mobile"
    this.messagemobile = ""

    this.formvalid= false;
    

    return false;
  }
}
onKeydown(event) {
  // use this for check 0-9
  var key = window.event ? event.keyCode : event.which;
  if (event.key != "%") {
    if (
      event.keyCode == 8 ||
      event.keyCode == 46 ||
      event.keyCode == 37 ||
      event.keyCode == 39
    ) {
      return true;
    } else if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  } else {
    event.preventDefault();
    return false;
  }
}

submitFormsss(){
    if(this.name == null || this.name == undefined || this.name == "" ){
      this.messagename = "You can't leave this empty"
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagename = ""
    }


    if(this.gender == null || this.gender == undefined ){
      this.messagegender = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagegender = ""

    }


    if(this.email == null || this.email == undefined ||this.email == "" ){
      this.messageemail = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messageemail = ""

    }



    if(this.mobile == null || this.mobile == undefined || this.mobile == "" ){
      this.messagemobile = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagemobile = ""

    }


    if(this.house == null || this.house == undefined || this.house == "" ){
      this.messagehouse = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagehouse = ""

    }



    if(this.street == null || this.street == undefined || this.street == "" ){
      this.messagestreet = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagestreet = ""

    }


    if(this.country_id == null || this.country_id== undefined ){
      this.messagecountry = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagecountry = ""

    }


    if(this.dob == null || this.dob == undefined || this.dob == ""){
      this.messagedob = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagedob = ""

    }


    if(this.district == null || this.district == undefined || this.district == "" ){
      this.messagedistrict = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagedistrict = ""

    }


    if(this.state_id == null || this.state_id == undefined ){
      this.messagestate = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagestate = ""

    }



    if(this.post_office == null || this.post_office == undefined || this.post_office == ""){
      this.messagepostoffice = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagepostoffice = ""

    }


    if(this.pincode == null || this.pincode == undefined || this.pincode == "" ){
      this.messagepin = "You can't leave this empty"
      
      this.formvalid= false;
      
    }
    else {
      this.formvalid= true;
      this.messagepin = ""

    }
    this.userType=sessionStorage.getItem('subscriber_id');

    if(this.formvalid== true && this.pincode!= "" && this.pincode != undefined
    && this.post_office!= "" && this.post_office != undefined && this.name!= ""  && this.name!= undefined  && this.house!= ""  && this.house!= undefined && this.dob!= ""
    && this.dob!= undefined && this.mobile!= "" && this.mobile!= undefined &&  this.street!= "" &&  this.street!= undefined && this.state_id!= "" && this.state_id!= undefined && this.country_id!= ""
    && this.country_id!= undefined && this.district!= ""  && this.district!= undefined
    && this.email!= "" && this.email!= undefined && this.gender!= "" && this.gender!= undefined && this.ValidateEmail()== true && this.ValidateEmail()==true)
     {
      let param= {
        userid:this.userType,
        name:this.name,
        gender:this.gender,
        // package_id:this.packageId,
        // only_softcopy:this.onlySoft,
        // user_type:'2',
        dob:this.dob,
        email:this.email,
        primary_mobile:this.mobile,
        whatsup_number:this.watsap,
        landline_number:this.landline,
        job_title:this.currently_employed == true ? this.job: "",
        company_name: this.currently_employed == true ? this.company_name : "",
        company_address:this.currently_employed == true ? this.company_address: "",
        housename:this.house,
        street:this.street,
        country_id:this.country_id,
        state_id:this.state_id,
        district:this.district,
        currently_employed:this.currently_employed == true ? "1": "0",
        post_office:this.post_office,
        pincode:this.pincode,
        // is_active:true,
      }
          console.log(param);
          return this.httpServic.postServiceOfficial('subscribers/subscriberedit', param)
          .subscribe(data=>{
          
          if(data.status==0 )
          {
            this.mesage= data.message;
            
          }
          if(data.status==0 && data.data==0)
          {
            alert("something went wrong , please check the details");
            return;
          }
          if(data.status==1 ){
          this.mesage= "";
            
          alert("updated successfully")

      }
     
      
    }


    );
    }
    
 
}

    

  
  
     
  
   
     
  

}
