import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/services/http-service';
import { UserService } from 'src/app/services/user-service';
import { DataService } from 'src/app/services/data-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-renew',
  templateUrl: './renew.component.html',
  styleUrls: ['./renew.component.scss']
})
export class RenewagentComponent implements OnInit {

    collectiondetailsFormGroup: FormGroup;
    submitted = false;
    public status: any;
  public sub_id: any;
  public agent_id: any;
  public package_id: any;
  public collection_mode: any;
  public packages: Array<any> = [];
 
    userdetails: any;
    subscribeData: any;
    isAbroad: number;
    
    constructor(private router: Router, 
     
      private httpServic: HttpClientService,
      
      private fromBuilder: FormBuilder,
      private userService: UserService, 
      private dataService: DataService) { }
      
    
  
    ngOnInit() {
      this.createcollectiondetails();
      
      if(this.dataService.getData()) {
        this.subscribeData = this.dataService.getData().subscribe(data => {
          console.log(data);
          if(data!=null) {
            this.userdetails = data;
            console.log(this.userdetails);
            
          }
        });
      }
    }
    
    
  
  
    createcollectiondetails() {
      this.collectiondetailsFormGroup = this.fromBuilder.group({
        collection_mode: ['',Validators.required],
        chequeno:[''],
        amount: ['',Validators.required],
        collected_date: ['',Validators.required],
        is_abroad:[''],
        package_id:['']
        
      });
    }
    onChangepackage(e)
  {
    if(e.target.value=1)
    {
      this.isAbroad=1
    }
    else{
      this.isAbroad=0
    }
      
    let param = {
      is_abroad: this.isAbroad
    }
    console.log(param)
    return this.httpServic.postServiceOfficialWithoutToken('packages/listPackages', param).subscribe(data=>{
      console.log(data.packages)
      
      
      this.packages=data.packages
     });
    
    
  }
    onChangecollectionmode(event)
  {
    console.log(event.target.value);
    this.collection_mode=event.target.value
  }
    showMsg: boolean = false;
    submit(){
      this.submitted = true;
    if (this.collectiondetailsFormGroup.invalid) {
      return;
  }
      this.status = 200;
      this.package_id=this.collectiondetailsFormGroup.value['package_id'];
      this.agent_id=sessionStorage.getItem('agent_id')
    this.sub_id=this.userdetails.sub_id  

      let param = {
        coll_mode_id: this.collection_mode,
        cheque_dd_no:this.collectiondetailsFormGroup.value['chequeno'],
        amount:this.collectiondetailsFormGroup.value['amount'],
        collected_date:this.collectiondetailsFormGroup.value['collected_date'],
        agent_id: this.agent_id,
        user_id: this.sub_id,
        package_id: this.package_id
      }
     // let param = this.collectiondetailsFormGroup.value;
     console.log(param);
     //this.username=this.LoginFormGroup.value['username'],
      
      
      return this.httpServic.postServiceOfficialWithoutToken('payment/renewSubscription', param).subscribe(data=>{
        console.log(data);
        this.showMsg= true;
       
  
        setTimeout(() => {
          this.router.navigateByUrl('dashboard');
        }, 2000);
        
     
      sessionStorage.removeItem('sub_ID');
      sessionStorage.removeItem('package_id');
      });
      
   }  
   onCancelClick()
   {
     this.router.navigateByUrl('dashboard');
   }

}